import { useSelector } from "react-redux";
import { get } from "lodash";

import {
    REQUEST_MASTER_MOBILE_PRODUCT,
    REQUEST_CREATE_MASTER_MOBILE_PRODUCT,
    REQUEST_UPDATE_MASTER_MOBILE_PRODUCT,
    SET_MASTER_MOBILE_PRODUCT,
    SUCCESS_CREATE_MASTER_MOBILE_PRODUCT,
    SUCCESS_UPDATE_MASTER_MOBILE_PRODUCT,
    ERROR_GET_MASTER_MOBILE_PRODUCT,
    ERROR_CREATE_MASTER_MOBILE_PRODUCT,
    ERROR_UPDATE_MASTER_MOBILE_PRODUCT,
    SET_MESSAGE_MASTER_MOBILE_PRODUCT,
} from "./mobileproductActionTypes"
import { IMobileProducts } from "../../types/IMobileProducts";

interface IState {
    busy: boolean;
    message: string;
    masterMobileProducts: IMobileProducts[];
}

const initialState: IState =
{
    busy: false,
    message: "",
    masterMobileProducts: [],
};

const mobileproducts = (state = initialState, action: Record<string, any>) => {
    switch (action.type) {
        case REQUEST_MASTER_MOBILE_PRODUCT:
        case REQUEST_CREATE_MASTER_MOBILE_PRODUCT:
        case REQUEST_UPDATE_MASTER_MOBILE_PRODUCT:
            return {
                ...state,
                busy: true,
                message: "",
            };

        case SET_MESSAGE_MASTER_MOBILE_PRODUCT:
            return {
                ...state,
                message: "",
            };

        case SET_MASTER_MOBILE_PRODUCT:
            return {
                ...state,
                busy: false,
                message: "",
                masterMobileProducts: action.payload,
            };

        case SUCCESS_CREATE_MASTER_MOBILE_PRODUCT:
            return {
                ...state,
                busy: false,
                message: "",
                masterMobileProducts: [...state.masterMobileProducts, action.payload],
            };

        case SUCCESS_UPDATE_MASTER_MOBILE_PRODUCT:
            return {
                ...state,
                busy: false,
                message: "",
                masterMobileProducts: state.masterMobileProducts.map((mp) => {
                    return get(mp, "_id") === action.payload._id ? action.payload : mp;
                }),
            };

        case ERROR_GET_MASTER_MOBILE_PRODUCT:
        case ERROR_CREATE_MASTER_MOBILE_PRODUCT:
        case ERROR_UPDATE_MASTER_MOBILE_PRODUCT:
            return {
                ...state,
                busy: false,
                message:
                    action.payload || "Something happened wrong try again after sometime",
            };
        default:
            return state;
    }
}

export default mobileproducts;


export function useMobileProductsMaster() {
    return useSelector((state: Record<string, any>) => state.mobileproducts);
}