import { Flex, FormControl, FormLabel, HStack, Spacer } from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import Select from "react-select";
// import styles from "./SelectFormField.module.css";
import normalStyles from "../formField/FormField.module.css";

interface Props {
  options: any;
  value: any;
  required: boolean;
  isMulti: boolean;
  name: string;
  fieldName: string;
  placeholder: string;
  error: string | undefined;
}
// const customStyles = {
//   option: (provided: any, state: any) => ({
//     ...provided,
//     borderBottom: "1px dotted #3c8578",
//   }),
//   control: (styles: any) => {
//     return {
//       ...styles,
//       // none of react-select's styles are passed to <Control />
//       // '&:hover' : {
//       //   border: "none",
//       //   boxShadow: "none"
//       // },
//       width: "100%",
//       border: "none",
//       backgroundColor: "transparent",
//     };
//   },
//   singleValue: (provided: any, state: any) => {
//     const opacity = state.isDisabled ? 0.5 : 1;
//     const transition = "opacity 300ms";

//     return { ...provided, opacity, transition };
//   },
// };

const SelectFormField: React.FC<Props> = (props) => {
  return (
    <FormControl isRequired={props.required}>
      <Flex width="100%">
        <HStack>
          <FormLabel>{props.name}</FormLabel>
          <Spacer></Spacer>
          <p className={normalStyles.error}>{props.error}</p>
        </HStack>
      </Flex>
      <Field name={props.fieldName}>
        {/* @ts-ignore */}
        {({ field, form }) => {
          return (
            <Select
              // id={"select"}
              value={props.value}
              // styles={customStyles}
              isMulti={props.isMulti}
              name="type"
              isDisabled={false}
              onChange={(e) => {
                //@ts-ignore
                form.setFieldValue(props.fieldName, e);
              }}
              options={props.options}
              placeholder={props.placeholder}
            />
          );
        }}
      </Field>
      {/* </InputGroup> */}
    </FormControl>
  );
};

export default SelectFormField;
