import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/saga";
import accountSaga from "./account/saga";
import betaUserSaga from "./betaUser/saga";
import linkMasterSaga from "./linkMaster/saga";
import linkCategorySaga from "./linkCategory/saga";
import usersSaga from "./user/saga";
import feedbackSaga from "./feedback/saga";
import feedbackMessageTypeSaga from "./feedbackMessageType/saga";
import productSaga from "./product/saga";
import couponSaga from "./coupon/saga";
import promoCodeSaga from "./promoCode/saga";
import mobileproductSaga from "./mobileproduct/saga";
import mobilecouponSaga from "./mobilecoupon/saga";
import tutorialMasterSaga from "./tutorial/saga";
import faqMasterSaga from "./faq/saga";
import tagMasterSaga from "./tag/saga";
import reportMasterSaga from "./report/saga";
import analyticsMasterSaga from "./analytics/saga";
import suspendsMasterSaga from "./suspendType/saga";
import instructionMasterSaga from "./Instruction/saga";
export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(accountSaga),
    fork(betaUserSaga),
    fork(linkMasterSaga),
    fork(linkCategorySaga),
    fork(usersSaga),
    fork(feedbackSaga),
    fork(feedbackMessageTypeSaga),
    fork(productSaga),
    fork(couponSaga),
    fork(promoCodeSaga),
    fork(mobileproductSaga),
    fork(mobilecouponSaga),
    fork(tutorialMasterSaga),
    fork(faqMasterSaga),
    fork(tagMasterSaga),
    fork(reportMasterSaga),
    fork(analyticsMasterSaga),
    fork(suspendsMasterSaga),
    fork(instructionMasterSaga),
  ]);
}
