import { useSelector } from "react-redux";
import { ICoupons } from "../../types/coupon";
import { get } from "lodash";

import {
  REQUEST_MASTER_COUPON,
  REQUEST_CREATE_MASTER_COUPON,
  REQUEST_UPDATE_MASTER_COUPON,
  SET_MASTER_COUPON,
  SUCCESS_CREATE_MASTER_COUPON,
  SUCCESS_UPDATE_MASTER_COUPON,
  ERROR_GET_MASTER_COUPON,
  ERROR_CREATE_MASTER_COUPON,
  ERROR_UPDATE_MASTER_COUPON,
  SET_MESSAGE_MASTER_COUPON,
  REQUEST_DELETE_MASTER_COUPON,
  SUCCESS_DELETE_MASTER_COUPON,
  ERROR_DELETE_MASTER_COUPON,
} from "./couponActionType";

interface IState {
  busy: boolean;
  message: string;
  masterCoupons: ICoupons[];
}

const initialState: IState = {
  busy: false,
  message: "",
  masterCoupons: [],
};

const coupons = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_MASTER_COUPON:
    case REQUEST_CREATE_MASTER_COUPON:
    case REQUEST_UPDATE_MASTER_COUPON:
      case REQUEST_DELETE_MASTER_COUPON:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_MESSAGE_MASTER_COUPON:
      return {
        ...state,
        message: "",
      };

    case SET_MASTER_COUPON:
      return {
        ...state,
        busy: false,
        message: "",
        masterCoupons: action.payload,
      };

      case SUCCESS_DELETE_MASTER_COUPON:
      return {
        ...state,
        busy: false,
        message: "",
        masterCoupons: state.masterCoupons.filter(
          (val) => get(val, "_id") !== action.payload
        ),
      };

    case SUCCESS_CREATE_MASTER_COUPON:
      return {
        ...state,
        busy: false,
        message: "",
        masterCoupons: [...state.masterCoupons, action.payload],
      };

    case SUCCESS_UPDATE_MASTER_COUPON:
      return {
        ...state,
        busy: false,
        message: "",
        masterCoupons: state.masterCoupons.map((mp) => {
          return get(mp, "_id") === action.payload._id ? action.payload : mp;
        }),
      };

    case ERROR_GET_MASTER_COUPON:
    case ERROR_CREATE_MASTER_COUPON:
    case ERROR_UPDATE_MASTER_COUPON:
      case ERROR_DELETE_MASTER_COUPON:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};

export default coupons;

export function useCouponsMaster() {
  return useSelector((state: Record<string, any>) => state.coupons);
}
