import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  AlertTitle,
  Button,
  Drawer,
  DrawerBody,
  IconButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  useToast,
  Box,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { get } from "lodash";
import Select from "react-select";
import { MdModeEditOutline } from "react-icons/md";
import { FormField } from "../form/formField/FormField";
import {
  REQUEST_CREATE_MASTER_TAG,
  REQUEST_MASTER_TAG,
  SET_MESSAGE_MASTER_TAG,
} from "../../store/tag/tagActionTypes";
import { useTagsMaster } from "../../store/tag/reducer";
import ImageFormField from "../ImageFormField/ImageFormField";

interface Props {
  tag?: any;
  isOpen: any;
  onClose: any;
}

const addSchema = Yup.object({
  size: Yup.string().optional(),
  color: Yup.object().required("Required"),
  batchNo: Yup.string().required("Required"),
  // batchNo: Yup.number().typeError("Must be only digits").required("Required"),
  numberOfTag: Yup.number().required("Required"),
  type: Yup.object().required("Required"),
  // isCustomTag: Yup.boolean().required("Required"),
  // random: Yup.string().required("Required"),
});

const TagEditDrawer: React.FC<Props> = ({ tag, isOpen, onClose }) => {
  const { masterTags, busy, message } = useTagsMaster();

  const toast = useToast();
  const [isDisabled, setDisabled] = useState(!!(tag && tag._id));

  const dispatch = useDispatch();

  const QRTypeOptions: any[] = useMemo(() => {
    return [
      { value: "PC", label: "PVC CARDS" },
      { value: "MC", label: "METAL CARDS" },
      // { value: "BMC", label: "BLANK METAL CARDS" },
      { value: "RE", label: "ROUND EPOXY" },
      { value: "SE", label: "SQUARE EPOXY" },
      // { value: "ST", label: "SQUARE TAG ( PLASTIC )" },
      // { value: "RT", label: "ROUND TAG ( PLASTIC )" },
      { value: "CC", label: "CUSTOM CARD" },
    ];
  }, []);

  const colorOptions: any[] = useMemo(() => {
    return [
      { value: "BK", label: "BLACK" },
      { value: "WT", label: "WHITE" },
      { value: "HG", label: "HOLOGRAM" },
      { value: "MBK", label: "MATTE BLACK" },
      { value: "MWT", label: "MATTE WHITE" },
      { value: "MHG", label: "MATTE HOLOGRAM" },
    ];
  }, []);
  const roundEpoxyOptions: any[] = useMemo(() => {
    return [
      { value: "BK", label: "BLACK" },
      { value: "WT", label: "WHITE" },
      { value: "HG", label: "HOLOGRAM" },
    ];
  }, []);
  const pvcCardOptions: any[] = useMemo(() => {
    return [
      { value: "MBK", label: "MATTE BLACK" },
      { value: "MWT", label: "MATTE WHITE" },
      { value: "MHG", label: "MATTE HOLOGRAM" },
    ];
  }, []);
  const whiteOptions: any[] = useMemo(() => {
    return [{ value: "WT", label: "WHITE" }];
  }, []);

  const initialValue = useMemo(() => {
    if (tag) {
      return {
        color: colorOptions.find((to) => to.value === tag.platform),
        type: QRTypeOptions.find((to) => to.value === tag.productType),
        size: tag.size,
        batchNo: tag.discountPrice,
        // random: tag.random,
        numberOfTag: tag.numberOfTag,
        tagImage: tag.tagImage,
        // isCustomTag: tag.isCustomTag,
      };
    }
    return {
      size: "",
      batchNo: "",
      color: "",
      type: "",
      // random: "",
      numberOfTag: "",
      tagImage: "",
      // isCustomTag: false,
    };
  }, [tag, colorOptions, QRTypeOptions]);

  const onSubmit = (values: Record<string, any>) => {
    if (tag) {
      // let payload: Record<string, any> = {
      //   size: values.size,
      //   batchNo: values.batchNo,
      //   // random: values.random,
      //   numberOfTag: values.numberOfTag,
      //   color: values.color.value.toString(),
      //   type: values.type.value.toString(),
      // };
      // const data = { payload, _id: tag._id };
      //   dispatch({ type: REQUEST_UPDATE_MASTER_TAG, data });
    } else {
      let payload: Record<string, any> = {
        size: values.size.toString() ? values.size.toString() : "",
        batchNo: values.batchNo.toString(),
        // random: values.random,
        numberOfTags: values.numberOfTag,
        color: values.color.value.toString(),
        type: values.type.value.toString(),
        isCustomTag: values.type.value.toString() === "CC" ? false : false,
        tagImage:
          values.type.value.toString() === "CC"
            ? values.tagImage
              ? values.tagImage
              : null
            : null,
      };
      dispatch({ type: REQUEST_CREATE_MASTER_TAG, payload });
      // dispatch({ type: REQUEST_MASTER_TAG });
      // console.log(payload);
    }
  };

  const products = useMemo(() => {
    return masterTags;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tag) {
      const productFromStore = masterTags.find((ml: any) => ml._id === tag._id);
      if (productFromStore !== tag) {
        toast({
          title: "Success.",
          description: "Tag is updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        onClose();
      }
    } else if (products.length !== masterTags.length) {
      const page = 1;
      toast({
        title: "Success.",
        description: "Tag is created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
      dispatch({
        type: REQUEST_MASTER_TAG,
        data: { page },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, masterTags, tag, onClose, toast]);

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, touched, submitForm }) => (
        <Form>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size={"md"}
            onClose={() => {
              onClose();
              dispatch({ type: SET_MESSAGE_MASTER_TAG });
            }}
            closeOnOverlayClick={busy}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                <Flex>
                  {get(tag, "tagId") || "Add Tag"}
                  <Spacer></Spacer>
                  {tag && tag._id && (
                    <IconButton
                      aria-label="update tag"
                      key={"updateTagButton"}
                      alignSelf={"end"}
                      onClick={() => {
                        setDisabled(!isDisabled);
                      }}
                      icon={<MdModeEditOutline />}
                    ></IconButton>
                  )}
                </Flex>

                {message && (
                  <Alert status="error">
                    <AlertTitle>{message}</AlertTitle>
                  </Alert>
                )}
              </DrawerHeader>
              <DrawerBody>
                <FormControl isRequired>
                  <Flex width="100%">
                    <HStack>
                      <FormLabel>Type : </FormLabel>
                      <Spacer></Spacer>
                      <p style={{ color: "red" }}>{errors.type}</p>
                    </HStack>
                  </Flex>
                  <Select
                    value={values["type"]}
                    name="type"
                    isDisabled={!!(isDisabled || tag)}
                    onChange={(event) => {
                      //@ts-ignore
                      setFieldValue("type", event);
                    }}
                    options={QRTypeOptions}
                    placeholder="Select a QRType"
                  />
                </FormControl>
                <FormControl isRequired>
                  <Flex width="100%">
                    <HStack>
                      <FormLabel>Color : </FormLabel>
                      <Spacer></Spacer>
                      <p style={{ color: "red" }}>{errors.color}</p>
                    </HStack>
                  </Flex>
                  <Select
                    // Disabled={isDisabled}
                    value={values["color"]}
                    name="color"
                    isDisabled={!!(isDisabled || tag)}
                    onChange={(event) => {
                      //@ts-ignore
                      setFieldValue("color", event);
                    }}
                    options={
                      values.type.value === "RE"
                        ? roundEpoxyOptions
                        : values.type.value === "PC" ||
                          values.type.value === "MC"
                        ? pvcCardOptions
                        : values.type.value === "SE"
                        ? // values.type.value === "CC"
                          whiteOptions
                        : colorOptions
                    }
                    placeholder="Select a Color"
                  />
                </FormControl>
                <FormField
                  required={false}
                  disabled={isDisabled}
                  name="Size : "
                  fieldName="size"
                  type="number"
                  error={touched.size ? (errors.size as string) : undefined}
                />

                {/* <Box paddingTop={1} paddingBottom={1}>
                  <p>
                    {touched.isCustomTag
                      ? (errors.isCustomTag as string)
                      : undefined}
                  </p>
                  <FormControl isRequired>
                    <RadioGroup
                      onChange={(val) => {
                        setFieldValue("isCustomTag", val === "true");
                      }}
                      value={values.isCustomTag?.toString()}
                    >
                      <Stack direction="row">
                        <FormLabel>Custom :</FormLabel>
                        <Radio value={"true"}>True</Radio>
                        <Radio value={"false"}>False</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box> */}

                <FormField
                  required={true}
                  disabled={isDisabled}
                  name="Batch No. : "
                  fieldName="batchNo"
                  type="text"
                  error={
                    touched.batchNo ? (errors.batchNo as string) : undefined
                  }
                />
                {/* 
                <FormField
                  // disabled={!!(isDisabled || product)}
                  disabled={isDisabled}
                  required={true}
                  name="Random No. : "
                  fieldName="random"
                  type="number"
                  error={
                    touched.random
                      ? (errors.random as string)
                      : undefined
                  }
                /> */}

                <FormField
                  required={true}
                  disabled={isDisabled}
                  name="Number of Tags : "
                  fieldName="numberOfTag"
                  type="text"
                  error={
                    touched.numberOfTag
                      ? (errors.numberOfTag as string)
                      : undefined
                  }
                />
                {values.type.value === "CC" ? (
                  <div>
                    <Flex width="100%">
                      <HStack>
                        <FormLabel>Custom Card Image : </FormLabel>
                      </HStack>
                    </Flex>
                    <ImageFormField
                      disabled={isDisabled || busy}
                      image={values["tagImage"]}
                      onSuccess={({ img: image }: { img: string }) => {
                        if (image) setFieldValue("tagImage", image);
                      }}
                    />
                  </div>
                ) : null}
              </DrawerBody>
              <DrawerFooter backgroundColor={"gray.100"}>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    onClose();
                    dispatch({ type: SET_MESSAGE_MASTER_TAG });
                  }}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={submitForm}
                  disabled={busy || isDisabled}
                >
                  {tag && tag._id ? "Update" : "Save"}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};

export default TagEditDrawer;
