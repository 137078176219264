import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import UserTable from "../../componenets/UserTable/UserTable";
import { CSVLink } from "react-csv";
import { useUsers } from "../../store/user/reducer";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import "react-datepicker/dist/react-datepicker.css";

const User = () => {
  const [searchQuery, setSearch] = useState<any>("");
  const [csvData, setCSVData] = useState<any>([]);
  const { users, busy } = useUsers();
  const [date, setDate] = useState<any>(["", ""]);
  const [confirmDate, setconfirmDate] = useState<any>(["", ""]);
  const [startDates, endDate] = date;
  // console.log(confirmDate);

  const [searchParams, setSearchParams] = useSearchParams();
  const [proUser, setProUser] = useState<boolean>(true);

  useEffect(() => {
    if (searchParams.get("email") !== null) {
      const data = searchParams.get("email");
      setSearch(data);
    }
  }, [searchParams]);

  useEffect(() => {
    setCSVData(
      users.map((value: any) => {
        return {
          EMAIL: value.email,
          FIRSTNAME: value.firstName ? value.firstName : "",
          LASTNAME: value.lastName ? value.lastName : "",
          PHONENUMBER: value.phoneNumber ? value.phoneNumber : "",
          DOB: value.DOB ? moment(value.DOB).format("MM/DD/YYYY") : "",
          CREATION_DATE: value.createdAt
            ? moment(value.createdAt).format("MM/DD/YYYY")
            : "01-07-2022",
          GENDER: value.gender ? value.gender : "",
        };
      })
    );
  }, [users]);
  return (
    <>
      <MobileView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Users
          </Heading>
          <Spacer></Spacer>
          <Flex>
            <Button mr={5}>
              {!busy && !(users || []).length ? null : (
                <CSVLink data={csvData} filename="user">
                  Excel
                </CSVLink>
              )}
            </Button>
            <Heading size="md" fontWeight="semibold">
              <Center>
                <Input
                  id="search"
                  type="text"
                  value={searchQuery}
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </Center>
            </Heading>
          </Flex>
          <Flex>
            <Box>
              <DatePicker
                className="customDatePicker"
                // selected={date ? new Date(date) : null}
                // peekNextMonth
                // showMonthDropdown
                // showYearDropdown
                selectsRange={true}
                startDate={startDates}
                endDate={endDate}
                onChange={(e: any) => {
                  const newDate = e.map((val: any) => {
                    return moment(val).format("MM/DD/YYYY");
                  });
                  setDate(e ? e : "");
                  setconfirmDate(e ? newDate : "");
                }}
                placeholderText={`📆 Select Date`}
                isClearable={true}
                // dropdownMode="select"
                maxDate={new Date()}
              />
            </Box>
            <Select
              width="100px"
              ml="4"
              placeholder="All"
              key={"All"}
              onChange={(event: any) => {
                setProUser(event.target.value);
              }}
            >
              <option value="TRUE" key={"true"}>
                Pro
              </option>
              <option value="FALSE" key={"false"}>
                Free
              </option>
            </Select>
          </Flex>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Users
            </Heading>

            <Spacer></Spacer>
            <HStack>
              <Box>
                <DatePicker
                  className="customDatePicker"
                  // selected={date ? new Date(date) : null}
                  // peekNextMonth
                  // showMonthDropdown
                  // showYearDropdown
                  selectsRange={true}
                  startDate={startDates}
                  endDate={endDate}
                  onChange={(e: any) => {
                    const newDate = e.map((val: any) => {
                      return moment(val).format("MM/DD/YYYY");
                    });
                    setDate(e ? e : "");
                    setconfirmDate(e ? newDate : "");
                  }}
                  placeholderText={`📆 Select Date`}
                  isClearable={true}
                  // dropdownMode="select"
                  maxDate={new Date()}
                />
              </Box>
              <Select
                width="100px"
                placeholder="All"
                key={"All"}
                onChange={(event: any) => {
                  setProUser(event.target.value);
                }}
              >
                <option value="TRUE" key={"true"}>
                  Pro
                </option>
                <option value="FALSE" key={"false"}>
                  Free
                </option>
              </Select>
              <Button mr={5}>
                {!busy && !(users || []).length ? null : (
                  <CSVLink data={csvData} filename="user">
                    Excel
                  </CSVLink>
                )}
              </Button>
              <Heading size="md" fontWeight="semibold">
                <Center>
                  <Input
                    id="search"
                    type="text"
                    value={searchQuery}
                    placeholder="Search..."
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  ></Input>
                </Center>
              </Heading>
            </HStack>
          </Flex>
        </VStack>
      </BrowserView>
      <UserTable
        searchQuery={searchQuery}
        setCSVData={setCSVData}
        proUser={proUser}
        startDate={confirmDate}
      />
    </>
  );
};

export default User;
