export const REQUEST_MASTER_LINKS = "REQUEST_MASTER_LINKS";
export const SET_MASTER_LINKS = "SET_MASTER_LINKS";

export const REQUEST_DELETE_MASTER_LINK = "REQUEST_DELETE_MASTER_LINK";
export const SUCCESS_DELETE_MASTER_LINK = "SUCCESS_DELETE_MASTER_LINK";
export const ERROR_DELETE_MASTER_LINK = "ERROR_DELETE_MASTER_LINK";

export const REQUEST_DEACTIVE_MASTER_LINK = "REQUEST_DEACTIVE_MASTER_LINK";
export const SUCCESS_DEACTIVE_MASTER_LINK = "SUCCESS_DEACTIVE_MASTER_LINK";
export const ERROR_DEACTIVE_MASTER_LINK = "ERROR_DEACTIVE_MASTER_LINK";

export const REQUEST_CREATE_MASTER_LINK = "REQUEST_CREATE_MASTER_LINK";
export const SUCCESS_CREATE_MASTER_LINK = "SUCCESS_CREATE_MASTER_LINK";
export const ERROR_CREATE_MASTER_LINK = "ERROR_CREATE_MASTER_LINK";

export const REQUEST_UPDATE_MASTER_LINK = "REQUEST_UPDATE_MASTER_LINK";
export const SUCCESS_UPDATE_MASTER_LINK = "SUCCESS_UPDATE_MASTER_LINK";
export const ERROR_UPDATE_MASTER_LINK = "ERROR_UPDATE_MASTER_LINK";
