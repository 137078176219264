import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DELETE_USER_REQUEST } from "../../store/account/accountActionType";
import { useAccounts } from "../../store/account/reducer";
import { IAccount } from "../../types/account";
interface Props {
  account: IAccount;
  isOpen: any;
  onClose: any;
}
const AccountTagView: React.FC<Props> = ({ isOpen, onClose, account }) => {
  const dispatch = useDispatch();
  //   const { busy, message, accounts } = useAccounts();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  // const { masterEvent: events } = useEventMaster();
  const { _id } = account;
  const toast = useToast();

  useEffect(() => {
    // console.log(ticketId);
    // console.log(events);
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/tags/byAccId/${_id}`)
      .then(function (response) {
        console.log(response);
        setData(response.data);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        toast({
          title: "Error.",
          description: "Tag is not show",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      });
  }, [_id]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Account Tag</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant="simple">
            <Thead background="gray.300">
              <Tr>
                <Th>Sr No.</Th>
                <Th>Tag url</Th>
                {/* <Th>Email</Th>
                <Th>Seat</Th>
                <Th>Price</Th>
                <Th>Is DisCounted</Th>
                <Th>Is Cancelled</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {!loader ? (
                data.map((val: any, index: number) => {
                  return (
                    <>
                      <Tr
                        key={index}
                        backgroundColor={
                          val.isCancelled ? "red.100" : "transparent"
                        }
                      >
                        <Td>{index + 1}</Td>
                        <Td>
                          <a
                            href={`${process.env.REACT_APP_FRONTEND_URL}/${val.urlName}`}
                            target="_blank"
                          >{`${process.env.REACT_APP_FRONTEND_URL}/${val.urlName}`}</a>
                        </Td>
                      </Tr>
                    </>
                  );
                })
              ) : (
                <Spinner
                  mt="2"
                  mx="auto"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="md"
                />
              )}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default AccountTagView;
