import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Drawer,
  DrawerBody,
  IconButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  useToast,
  Text,
} from "@chakra-ui/react";
import { IProducts } from "../../types/product";
import * as Yup from "yup";
import { useProductsMaster } from "../../store/product/reducer";
import { useDispatch } from "react-redux";
import {
  REQUEST_CREATE_MASTER_PRODUCT,
  REQUEST_UPDATE_MASTER_PRODUCT,
  SET_MESSAGE_MASTER_PRODUCT,
} from "../../store/product/productActionTypes";
import { Form, Formik } from "formik";
import { get } from "lodash";
import Select from "react-select";
import { MdModeEditOutline } from "react-icons/md";
import { FormField } from "../form/formField/FormField";

interface Props {
  product?: IProducts;
  isOpen: any;
  onClose: any;
}

const addSchema = Yup.object({
  productName: Yup.string().required("Required"),
  productDesc: Yup.string().optional().nullable(),
  priceInterval: Yup.object().required("Required"),
  currentPrice: Yup.number().min(1).optional(),
  discountPrice: Yup.number().min(1).required("Required"),
  priceIntervalCount: Yup.number().required("Required"),
  active: Yup.boolean().required("Required"),
  productType: Yup.string().required("Required"),
});

const ProductEditDrawer: React.FC<Props> = ({ product, isOpen, onClose }) => {
  const { masterProducts, busy, message } = useProductsMaster();
  const toast = useToast();
  const [isDisabled, setDisabled] = useState(!!(product && product._id));

  const dispatch = useDispatch();

  const IntervalOptions: any[] = useMemo(() => {
    return [
      { value: "month", label: "month" },
      { value: "year", label: "year" },
      { value: "week", label: "week" },
      { value: "day", label: "day" },
    ];
  }, []);

  const initialValue = useMemo(() => {
    if (product) {
      return {
        productName: product.productName,
        productDesc: product.productDesc,
        priceInterval: IntervalOptions.find(
          (to) => to.value === product.priceInterval
        ),
        priceIntervalCount: product.priceIntervalCount,
        currentPrice: product.currentPrice,
        discountPrice: product.discountPrice / 100,
        active: product.active,
        productType: "SUBSCRIPTION",
      };
    }
    return {
      productName: "",
      productDesc: "",
      priceInterval: "",
      currentPrice: "",
      priceIntervalCount: 1,
      discountPrice: 0,
      active: true,
      productType: "SUBSCRIPTION",
    };
  }, [product, IntervalOptions]);

  const onSubmit = (values: Record<string, any>) => {
    if (product) {
      let payload: Record<string, any> = {
        productName: values.productName,
        productDesc: values.productDesc,
        // priceInterval: values.priceInterval.value,
        discountPrice: values.discountPrice,
        // priceIntervalCount: values.priceIntervalCount,
        active: values.active,
      };
      const data = { payload, _id: product._id };
      dispatch({ type: REQUEST_UPDATE_MASTER_PRODUCT, data });
    } else {
      let payload: Record<string, any> = {
        productName: values.productName,
        productDesc: values.productDesc,
        priceInterval: values.priceInterval.value,
        currentPrice: values.currentPrice,
        discountPrice: values.discountPrice * 100,
        priceIntervalCount: values.priceIntervalCount,
        active: values.active,
        productType: "SUBSCRIPTION",
      };

      dispatch({ type: REQUEST_CREATE_MASTER_PRODUCT, payload });
    }
  };

  const products = useMemo(() => {
    return masterProducts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (product) {
      const productFromStore = masterProducts.find(
        (ml: any) => ml._id === product._id
      );
      if (productFromStore !== product) {
        toast({
          title: "Success.",
          description: "Product is updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        onClose();
      }
    } else if (products.length !== masterProducts.length) {
      toast({
        title: "Success.",
        description: "Product is created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [products, masterProducts, product, onClose, toast]);

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, touched, submitForm }) => (
        <Form>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size={"md"}
            onClose={() => {
              onClose();
              dispatch({ type: SET_MESSAGE_MASTER_PRODUCT });
            }}
            closeOnOverlayClick={busy}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                <Flex>
                  {get(product, "productName") || "Add Product"}
                  <Spacer></Spacer>
                  {product && product._id && (
                    <IconButton
                      aria-label="update product"
                      key={"updateProductButton"}
                      alignSelf={"end"}
                      onClick={() => {
                        setDisabled(!isDisabled);
                      }}
                      icon={<MdModeEditOutline />}
                    ></IconButton>
                  )}
                </Flex>

                {message && (
                  <Alert status="error">
                    <AlertTitle>{message}</AlertTitle>
                  </Alert>
                )}
              </DrawerHeader>
              <DrawerBody>
                <FormField
                  required={true}
                  disabled={isDisabled}
                  name="Product Name : "
                  fieldName="productName"
                  type="text"
                  error={
                    touched.productName
                      ? (errors.productName as string)
                      : undefined
                  }
                />

                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Description : "
                  fieldName="productDesc"
                  type="text"
                  error={
                    touched.productDesc
                      ? (errors.productDesc as string)
                      : undefined
                  }
                />

                <FormControl isRequired>
                  <Flex width="100%">
                    <HStack>
                      <FormLabel>Price Interval : </FormLabel>
                      <Spacer></Spacer>
                      <p>{errors.priceInterval}</p>
                    </HStack>
                  </Flex>
                  <Select
                    value={values["priceInterval"]}
                    name="priceInterval"
                    isDisabled={!!(isDisabled || product)}
                    onChange={(event) => {
                      setFieldValue("priceInterval", event);
                    }}
                    options={IntervalOptions}
                    placeholder="Select a Type"
                  />
                </FormControl>

                <FormField
                  disabled={!!(isDisabled || product)}
                  required={true}
                  name="Renew Interval : "
                  fieldName="priceIntervalCount"
                  type="number"
                  error={
                    touched.priceIntervalCount
                      ? (errors.priceIntervalCount as string)
                      : undefined
                  }
                />

                <Box paddingTop={1} paddingBottom={1}>
                  <FormControl isRequired>
                    <RadioGroup
                      onChange={(val) => {
                        setFieldValue("active", val === "true");
                      }}
                      value={values.active?.toString()}
                    >
                      <Stack direction="row">
                        <FormLabel>Active :</FormLabel>
                        <Radio value={"true"}>TRUE</Radio>
                        <Radio value={"false"}>FALSE</Radio>
                        <Text>
                          {errors.active
                            ? (errors.active as string)
                            : undefined}
                        </Text>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>

                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Original Price : "
                  fieldName="currentPrice"
                  type="number"
                  error={
                    touched.currentPrice
                      ? (errors.currentPrice as string)
                      : undefined
                  }
                />

                <FormField
                  disabled={!!(isDisabled || product)}
                  required={true}
                  name="Discount Price : "
                  fieldName="discountPrice"
                  type="number"
                  error={
                    touched.discountPrice
                      ? (errors.discountPrice as string)
                      : undefined
                  }
                />
              </DrawerBody>
              <DrawerFooter backgroundColor={"gray.100"}>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    onClose();
                    dispatch({ type: SET_MESSAGE_MASTER_PRODUCT });
                  }}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={submitForm}
                  disabled={busy || isDisabled}
                >
                  {product && product._id ? "Update" : "Save"}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};

export default ProductEditDrawer;
