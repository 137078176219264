import { useState } from "react";

import {
  Center,
  Flex,
  Heading,
  HStack,
  Input,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import LinkMasterEditDrawer from "../../componenets/drawer/LinkMasterEditDrawer";
import LinksMasterTable from "../../componenets/LinksMasterTable/LinksMasterTable";
import { BrowserView, MobileView } from "react-device-detect";


const LinksMaster = () => {
  const [searchQuery, setSearch] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
    <MobileView>
    <VStack top={5} position="relative">
          <Heading  size="lg" fontWeight="semibold" color="black.400">
            Links
          </Heading>
          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <HStack>
              <Flex>
              <Center mr={3}>
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </Center>
              <Center
                bg="tomato"
                h="100%"
                color="white"
                p={3}
                onClick={() => {
                  onOpen();
                  //   setaddErrorMsg("");
                }}
                style={{ cursor: "pointer" }}
              >
                + Add Links
              </Center>
              </Flex>
            </HStack>
          </Heading>
      </VStack>
    </MobileView>
      <BrowserView>
      <VStack top={5} position="relative">
        <Flex w="92%">
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Links
          </Heading>

          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <HStack>
              <Center>
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </Center>
              <Center
                bg="tomato"
                h="100%"
                color="white"
                p={3}
                onClick={() => {
                  onOpen();
                  //   setaddErrorMsg("");
                }}
                style={{ cursor: "pointer" }}
              >
                + Add Links
              </Center>
            </HStack>
          </Heading>
        </Flex>
      </VStack>
      </BrowserView>
      <LinksMasterTable searchQuery={searchQuery} />
      {isOpen && <LinkMasterEditDrawer isOpen onClose={onClose} />}
    </>
  );
};

export default LinksMaster;
