import { useSelector } from "react-redux";
import { get } from "lodash";

import {
  REQUEST_MASTER_INSTRUCTION,
  REQUEST_CREATE_MASTER_INSTRUCTION,
  REQUEST_UPDATE_MASTER_INSTRUCTION,
  REQUEST_DELETE_MASTER_INSTRUCTION,
  SET_MASTER_INSTRUCTION,
  SUCCESS_CREATE_MASTER_INSTRUCTION,
  SUCCESS_UPDATE_MASTER_INSTRUCTION,
  SUCCESS_DELETE_MASTER_INSTRUCTION,
  ERROR_GET_MASTER_INSTRUCTION,
  ERROR_CREATE_MASTER_INSTRUCTION,
  ERROR_UPDATE_MASTER_INSTRUCTION,
  ERROR_DELETE_MASTER_INSTRUCTION,
  SET_MESSAGE_MASTER_INSTRUCTION,
} from "./instructionActionTypes";

interface IState {
  busy: boolean;
  message: string;
  masterInstruction: any;
}

const initialState: IState = {
  busy: false,
  message: "",
  masterInstruction: [],
};

const instruction = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_MASTER_INSTRUCTION:
    case REQUEST_CREATE_MASTER_INSTRUCTION:
    case REQUEST_UPDATE_MASTER_INSTRUCTION:
    case REQUEST_DELETE_MASTER_INSTRUCTION:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_MESSAGE_MASTER_INSTRUCTION:
      return {
        ...state,
        message: "",
      };

    case SET_MASTER_INSTRUCTION:
      return {
        ...state,
        busy: false,
        message: "",
        masterInstruction: action.payload,
      };

    case SUCCESS_CREATE_MASTER_INSTRUCTION:
      return {
        ...state,
        busy: false,
        message: "",
        masterInstruction: [...state.masterInstruction, action.payload],
      };

    case SUCCESS_DELETE_MASTER_INSTRUCTION:
      return {
        ...state,
        busy: false,
        message: "",
        masterInstruction: state.masterInstruction.filter(
          (faqs: any) => get(faqs, "_id") !== action.payload
        ),
      };

    case SUCCESS_UPDATE_MASTER_INSTRUCTION:
      return {
        ...state,
        busy: false,
        message: "",
        masterInstruction: state.masterInstruction.map((mp: any) => {
          return get(mp, "_id") === action.payload._id ? action.payload : mp;
        }),
      };

    case ERROR_GET_MASTER_INSTRUCTION:
    case ERROR_CREATE_MASTER_INSTRUCTION:
    case ERROR_UPDATE_MASTER_INSTRUCTION:
    case ERROR_DELETE_MASTER_INSTRUCTION:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};

export default instruction;

export function useInstructionMaster() {
  return useSelector((state: Record<string, any>) => state.instruction);
}
