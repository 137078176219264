export const REQUEST_MASTER_SUSPEND = "REQUEST_MASTER_SUSPEND";
export const SET_MASTER_SUSPEND = "SET_MASTER_SUSPEND";
export const ERROR_GET_MASTER_SUSPEND = "ERROR_GET_MASTER_SUSPEND";

export const REQUEST_CREATE_MASTER_SUSPEND = "REQUEST_CREATE_MASTER_SUSPEND";
export const SUCCESS_CREATE_MASTER_SUSPEND = "SUCCESS_CREATE_MASTER_SUSPEND";
export const ERROR_CREATE_MASTER_SUSPEND = "ERROR_CREATE_MASTER_SUSPEND";

export const REQUEST_UPDATE_MASTER_SUSPEND = "REQUEST_UPDATE_MASTER_SUSPEND";
export const SUCCESS_UPDATE_MASTER_SUSPEND = "SUCCESS_UPDATE_MASTER_SUSPEND";
export const ERROR_UPDATE_MASTER_SUSPEND = "ERROR_UPDATE_MASTER_SUSPEND";

export const REQUEST_DELETE_MASTER_SUSPEND = "REQUEST_DELETE_MASTER_SUSPEND";
export const SUCCESS_DELETE_MASTER_SUSPEND = "SUCCESS_DELETE_MASTER_SUSPEND";
export const ERROR_DELETE_MASTER_SUSPEND = "ERROR_DELETE_MASTER_SUSPEND";


export const SET_MESSAGE_MASTER_SUSPEND = "SET_MESSAGE_MASTER_SUSPEND";