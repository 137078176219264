import {
  Center,
  Flex,
  Heading,
  HStack,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import TutorialMasterEditDrawer from "../../componenets/drawer/TutorialMasterEditDrawer";
import TutorialTable from "../../componenets/TutorialTable/TutorialTable";
import { BrowserView, MobileView } from "react-device-detect";

const Tutorial = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <MobileView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Flex w="92%">
            <Heading
              ml="60px"
              mt="4px"
              size="lg"
              fontWeight="semibold"
              color="black.400"
            >
              Tutorial
            </Heading>

            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <HStack>
                <Center
                  bg="tomato"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Tutorial
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Tutorial
            </Heading>

            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <HStack>
                <Center
                  bg="tomato"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Tutorial
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </BrowserView>
      <TutorialTable />
      {isOpen && <TutorialMasterEditDrawer isOpen onClose={onClose} />}
    </>
  );
};

export default Tutorial;
