import {
  Center,
  Flex,
  Heading,
  HStack,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import TagEditDrawer from "../../componenets/drawer/TagEditDrawer";
import TagMasterTable from "../../componenets/TagMasterTable/TagMasterTable";
import { BrowserView, MobileView } from "react-device-detect";

const Tags = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <MobileView>
        <VStack top={5} position="relative">
          <Flex w="92%">
            <Heading
              ml="70px"
              mt="4px"
              size="lg"
              fontWeight="semibold"
              color="black.400"
            >
              Tag
            </Heading>

            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <HStack>
                <Center
                  bg="tomato"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Tag
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative">
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Tag
            </Heading>

            <Spacer></Spacer>

            <Heading size="md" fontWeight="semibold">
              <HStack>
                <Center
                  bg="tomato"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Tag
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </BrowserView>
      <TagMasterTable />
      {isOpen && <TagEditDrawer isOpen onClose={onClose} />}
    </>
  );
};

export default Tags;
