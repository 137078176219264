import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import AccountTable from "../../componenets/AccountTable/AccountsTable";
import { useAccounts } from "../../store/account/reducer";
import { REQUEST_ACCOUNTS } from "../../store/account/accountActionType";
import { CSVLink } from "react-csv";
import { get } from "lodash";
import { BrowserView, MobileView } from "react-device-detect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Accounts = () => {
  const { accounts, busy } = useAccounts();
  const [csvData, setCSVData] = useState<any>([]);
  const [date, setDate] = useState<any>(["", ""]);
  const [confirmDate, setconfirmDate] = useState<any>(["", ""]);
  const [startDates, endDate] = date;
  const [proUser, setProUser] = useState<boolean>(true);
  const [accountType, setAccountType] = useState<boolean>(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!busy && !(accounts || []).length) {
      dispatch({ type: REQUEST_ACCOUNTS });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCSVData(
      accounts.map((value: any) => {
        const data = { ...value };
        delete data.user;
        delete data._id;
        delete data.tags;
        delete data.pkpasses;
        delete data.profileImage;

        return {
          ...data,
          tags: get(value, "tags", []).length,
          appleWallet: get(value, "pkpasses", []).length,
          email: get(value, "user.email"),
          phoneNumber: get(value, "user.phoneNumber"),
          userType: get(value, "user.userType"),
        };
      })
    );
  }, [accounts]);

  const [searchQuery, setSearch] = useState("");
  return (
    <>
      <MobileView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Accounts
          </Heading>
          <Spacer></Spacer>
          <Flex style={{ margin: "0 10px" }}>
            <Button mr={1}>
              {!busy && !(accounts || []).length ? null : (
                <CSVLink data={csvData} filename="user">
                  Excel
                </CSVLink>
              )}
            </Button>
            <Heading size="md" fontWeight="semibold">
              <Center>
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </Center>
            </Heading>
            <Select
              width="100px"
              ml={1}
              placeholder="All"
              key={"All"}
              onChange={(event: any) => {
                setAccountType(event.target.value);
              }}
            >
              <option value="PERSONAL" key={"true"}>
                Personal
              </option>
              <option value="PROFESSIONAL" key={"false"}>
                Professional
              </option>
              <option value="APPLEWALLET" key={"wallet"}>
                Apple Wallet
              </option>
            </Select>
          </Flex>
          <Flex>
            <Box>
              <DatePicker
                className="customDatePicker"
                // selected={date ? new Date(date) : null}
                // peekNextMonth
                // showMonthDropdown
                // showYearDropdown
                selectsRange={true}
                startDate={startDates}
                endDate={endDate}
                onChange={(e: any) => {
                  const newDate = e.map((val: any) => {
                    return moment(val).format("MM/DD/YYYY");
                  });
                  setDate(e ? e : "");
                  setconfirmDate(e ? newDate : "");
                }}
                placeholderText={`📆 Select Date`}
                isClearable={true}
                // dropdownMode="select"
                maxDate={new Date()}
              />
            </Box>
            <Select
              width="100px"
              ml="4"
              placeholder="All"
              key={"All"}
              onChange={(event: any) => {
                setProUser(event.target.value);
              }}
            >
              <option value="TRUE" key={"true"}>
                Pro
              </option>
              <option value="FALSE" key={"false"}>
                Free
              </option>
            </Select>
          </Flex>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Accounts
            </Heading>
            <Spacer></Spacer>
            <HStack>
              <Box>
                <DatePicker
                  className="customDatePicker"
                  // selected={date ? new Date(date) : null}
                  // peekNextMonth
                  // showMonthDropdown
                  // showYearDropdown
                  selectsRange={true}
                  startDate={startDates}
                  endDate={endDate}
                  onChange={(e: any) => {
                    const newDate = e.map((val: any) => {
                      return moment(val).format("MM/DD/YYYY");
                    });
                    setDate(e ? e : "");
                    setconfirmDate(e ? newDate : "");
                  }}
                  placeholderText={`📆 Select Date`}
                  isClearable={true}
                  // dropdownMode="select"
                  maxDate={new Date()}
                />
              </Box>
              <Select
                width="100px"
                placeholder="All"
                key={"All"}
                onChange={(event: any) => {
                  setProUser(event.target.value);
                }}
              >
                <option value="TRUE" key={"true"}>
                  Pro
                </option>
                <option value="FALSE" key={"false"}>
                  Free
                </option>
              </Select>
              <Select
                width="120px"
                placeholder="All"
                key={"All"}
                onChange={(event: any) => {
                  setAccountType(event.target.value);
                }}
              >
                <option value="PERSONAL" key={"true"}>
                  Personal
                </option>
                <option value="PROFESSIONAL" key={"false"}>
                  Professional
                </option>
                <option value="APPLEWALLET" key={"wallet"}>
                  Apple Wallet
                </option>
              </Select>
              <Button mr={5}>
                {!busy && !(accounts || []).length ? null : (
                  <CSVLink data={csvData} filename="user">
                    Excel
                  </CSVLink>
                )}
              </Button>
              <Heading size="md" fontWeight="semibold">
                <Center>
                  <Input
                    id="search"
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  ></Input>
                </Center>
              </Heading>
            </HStack>
          </Flex>
        </VStack>
      </BrowserView>
      <AccountTable
        query={searchQuery}
        setCSVData={setCSVData}
        proUser={proUser}
        startDate={confirmDate}
        accountType={accountType}
      />
    </>
  );
};

export default Accounts;
