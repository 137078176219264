import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { REQUEST_UPDATE_ACCOUNT } from "../account/accountActionType";
import {
  REQUEST_USERS,
  SET_USERS,
  REQUEST_DELETE_USER,
  SUCCESS_DELETE_USER,
  ERROR_DELETE_USER,
  REQUEST_ASSIGN_ROLE_USER,
  SUCCESS_ASSIGN_ROLE_USER,
  ERROR_ASSIGN_ROLE_USER,
  PRO_USER_REQUEST,
  PRO_USER_SUCCESS,
  PRO_USER_ERROR,
  REQUEST_RESETPOPUP,
  SUCCESS_RESETPOPUP,
  ERROR_RESETPOPUP,
} from "./userActionType";
function* requestUsers(action: Record<string, any>): any {
  try {
    const result: any = yield call(geUserMaster);
    yield put({ type: SET_USERS, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}

function* requestDeleteMasterUser(action: Record<string, any>): any {
  try {
    yield call(deleteMasterUser, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_DELETE_USER,
      payload: get(action, "payload._id", ""),
    });
    yield put({
      type: REQUEST_UPDATE_ACCOUNT,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETE_USER, payload: message });
  }
}

function* requestAssignMasterUser(action: Record<string, any>): any {
  try {
    yield call(
      assignRole,
      get(action, "payload.userId", ""),
      get(action, "payload.userType", "USER")
    );
    yield put({
      type: SUCCESS_ASSIGN_ROLE_USER,
      payload: action.payload,
    });
    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    } else if (get(error, "response.status") === 401) {
      message = "Only Master Admin can change User Role.";
    }
    yield put({ type: ERROR_ASSIGN_ROLE_USER, payload: message });
  }
}

function* requestResetPopUp(action: Record<string, any>): any {
  
  try {
    yield call(
      resetPopup,
      action.payload._id,
      action.payload.payload,
    );
    yield put({
      type: SUCCESS_RESETPOPUP,
      payload: action.payload,
    });
    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    } else if (get(error, "response.status") === 401) {
      message = "Only Master Admin can change User Role.";
    }
    yield put({ type: ERROR_RESETPOPUP, payload: message });
  }
}

function* requestProMasterUser(action: Record<string, any>): any {
  try {
    yield call(proUser, action.data.userId, action.data.payload);
    yield put({
      type: PRO_USER_SUCCESS,
      payload: action.data,
    });
    action && action.onSuccess && action.onSuccess();
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: PRO_USER_ERROR, payload: message });
  }
}

export function geUserMaster() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/user/all`,
    withCredentials: true,
  });
}

export function deleteMasterUser(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/user/${_id}`,
    withCredentials: true,
  });
}

export function assignRole(_id: string, userType: boolean) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/admin/superAdmin/assignRole/${_id}`,
    withCredentials: true,
    data: { userType },
  });
}

export function resetPopup(_id: string,payload: Record<string, any>,) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/admin/userUpdate/resetPopup/${_id}`,
    withCredentials: true,
    data: payload,
  });
}

export function proUser(_id: string, payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/user/makeProUser/${_id}`,
    withCredentials: true,
    data: payload,
  });
}

const userSaga = function* () {
  yield all([
    takeLatest(REQUEST_USERS, requestUsers),
    takeLatest(REQUEST_DELETE_USER, requestDeleteMasterUser),
    takeLatest(REQUEST_ASSIGN_ROLE_USER, requestAssignMasterUser),
    takeLatest(REQUEST_RESETPOPUP, requestResetPopUp),
    takeLatest(PRO_USER_REQUEST, requestProMasterUser),
  ]);
};
export default userSaga;
