import { Button, useDisclosure } from "@chakra-ui/react";
import { Spin, Table } from "antd";

import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSuspendsMaster } from "../../store/suspendType/reducer";
import { REQUEST_MASTER_SUSPEND } from "../../store/suspendType/suspendActionTypes";
import MobileCouponEditDrawer from "../drawer/MobileCouponEditDrawer";
import SuspendTypeEditDrawer from "../drawer/SuspendTypeEditDrawer";

const SuspendTypeTable = () => {
  const dispatch = useDispatch();
  const { masterSuspends, busy } = useSuspendsMaster();

  useEffect(() => {
    if (!busy && !(masterSuspends || []).length) {
      dispatch({ type: REQUEST_MASTER_SUSPEND });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [targetCoupon, setTargetCoupon] = useState<any>();

  const viewCoupon = (productMaster: any) => {
    setTargetCoupon(productMaster);
    onViewOpen();
  };

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(
      masterSuspends.map((value: any, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        reason: value.reason,
        value: value,
      }))
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterSuspends]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
    },
    {
      title: "Suspend Type Reason",
      dataIndex: "reason",
    },
    {
      title: "ACTION",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Button
            onClick={() => {
              viewCoupon(value);
            }}
          >
            View
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "92vw",
          maxWidth: "92vw",
          height: "90vh",
          top: "8vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={data.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 4em)", x: 1300 }}
            rowClassName={() => "gray"}
          />
        )}
      </div>

      {isViewOpen && (
        <SuspendTypeEditDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          suspend={targetCoupon}
        />
      )}
    </>
  );
};

export default SuspendTypeTable;
