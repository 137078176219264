import { useSelector } from "react-redux";
import { get } from "lodash";

import {
  REQUEST_MASTER_SUSPEND,
  REQUEST_CREATE_MASTER_SUSPEND,
  REQUEST_UPDATE_MASTER_SUSPEND,
  SET_MASTER_SUSPEND,
  SUCCESS_CREATE_MASTER_SUSPEND,
  SUCCESS_UPDATE_MASTER_SUSPEND,
  ERROR_GET_MASTER_SUSPEND,
  ERROR_CREATE_MASTER_SUSPEND,
  ERROR_UPDATE_MASTER_SUSPEND,
  SET_MESSAGE_MASTER_SUSPEND,
  REQUEST_DELETE_MASTER_SUSPEND,
  SUCCESS_DELETE_MASTER_SUSPEND,
  ERROR_DELETE_MASTER_SUSPEND,
} from "./suspendActionTypes";

interface IState {
  busy: boolean;
  message: string;
  masterSuspends: any[];
}

const initialState: IState = {
  busy: false,
  message: "",
  masterSuspends: [],
};

const suspends = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_MASTER_SUSPEND:
    case REQUEST_CREATE_MASTER_SUSPEND:
    case REQUEST_UPDATE_MASTER_SUSPEND:
    case REQUEST_DELETE_MASTER_SUSPEND:
      return {
        ...state,
        busy: true,
        message: "",
      };

    case SET_MESSAGE_MASTER_SUSPEND:
      return {
        ...state,
        message: "",
      };

    case SET_MASTER_SUSPEND:
      return {
        ...state,
        busy: false,
        message: "",
        masterSuspends: action.payload,
      };

    case SUCCESS_CREATE_MASTER_SUSPEND:
      return {
        ...state,
        busy: false,
        message: "",
        masterSuspends: [...state.masterSuspends, action.payload],
      };

    case SUCCESS_UPDATE_MASTER_SUSPEND:
      return {
        ...state,
        busy: false,
        message: "",
        masterSuspends: state.masterSuspends.map((mp) => {
          return get(mp, "_id") === action.payload._id ? action.payload : mp;
        }),
      };

    case SUCCESS_DELETE_MASTER_SUSPEND:
      return {
        ...state,
        busy: false,
        message: "",
        masterSuspends: state.masterSuspends.filter(
          (val) => get(val, "_id") !== action.payload
        ),
      };

    case ERROR_GET_MASTER_SUSPEND:
    case ERROR_CREATE_MASTER_SUSPEND:
    case ERROR_UPDATE_MASTER_SUSPEND:
    case ERROR_DELETE_MASTER_SUSPEND:
      return {
        ...state,
        busy: false,
        message:
          action.payload || "Something happened wrong try again after sometime",
      };
    default:
      return state;
  }
};

export default suspends;

export function useSuspendsMaster() {
  return useSelector((state: Record<string, any>) => state.suspends);
}
