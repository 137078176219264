import {
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  IconButton,
  useDisclosure,
  // useDisclosure,
} from "@chakra-ui/react";
import { Spin, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { IoMdLogIn } from "react-icons/io";
import { AiOutlineLink } from "react-icons/ai";
import { get } from "lodash";
import { useAccounts } from "../../store/account/reducer";
import { IAccount } from "../../types/account";
import moment from "moment";
import { MdVerified } from "react-icons/md";
import AccountVerifyConformation from "../modals/AccountVerifyConformation";
import { getAuthToken } from "../../script/getAuthToken";
// import UserProConfirmation from "../modals/UserProConfirmation";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import AccountTagView from "../modals/AccountTagView";
interface AccountTableProps {
  query: string;
  setCSVData: any;
  startDate: any;
  proUser: any;
  accountType: any;
}
const AccountTable: React.FC<AccountTableProps> = ({
  query,
  setCSVData,
  proUser,
  startDate,
  accountType,
}) => {
  const { accounts, busy }: { accounts: any; busy: any } = useAccounts();
  const [errorMsg, setErrorMsg] = useState("");
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(1);
  const Navigate = useNavigate();
  const [targetAccount, setTargetAccount] = useState<IAccount>();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const viewEvent = (eventMaster: IAccount) => {
    setTargetAccount(eventMaster);
    onViewOpen();
  };

  const {
    isOpen: isVerifyOpen,
    onOpen: onVerifyOpen,
    onClose: onVerifyClose,
  } = useDisclosure();

  const searchColumns = useMemo(
    () => [
      "firstName",
      "lastName",
      "companyName",
      "accountName",
      "type",
      "user.email",
      "user.phoneNumber",
      "user.userType",
    ],
    []
  );

  const search = (account: Record<string, any>) => {
    const tempQuery = query.split(" ");
    return tempQuery.every((item) => {
      return (
        searchColumns.some((column) => {
          return (
            get(account, column, "")
              .toString()
              .toLowerCase()
              .indexOf(item.toLowerCase()) > -1
          );
        }) &&
        (!proUser.length ||
          (proUser === "TRUE" && account.user.isPro) ||
          (proUser === "FALSE" && !account.user.isPro)) &&
        (!accountType.length ||
          (accountType === "PERSONAL" && account.type === "PERSONAL") ||
          (accountType === "PROFESSIONAL" && account.type === "PROFESSIONAL") ||
          (accountType === "APPLEWALLET" && account.pkpasses.length > 0))
      );
    });
  };

  const verifyAccount = (value: IAccount) => {
    setTargetAccount(value);
    onVerifyOpen();
  };

  useEffect(() => {
    setCSVData(
      accounts.filter(search).map((value: any) => {
        const data = { ...value };
        delete data.user;
        delete data._id;
        delete data.tags;
        delete data.profileImage;
        delete data.pkpasses;

        return {
          ...data,
          tags: get(value, "tags", []).length,
          appleWallet: get(value, "pkpasses", []).length,
          email: get(value, "user.email"),
          phoneNumber: get(value, "user.phoneNumber"),
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      startDate[0] === "" ||
      (startDate[0] === "Invalid date" && startDate[1] === "") ||
      startDate[1] === "Invalid date"
    ) {
      setData(
        accounts.filter(search).map((value: any, index: number) => ({
          _id: value._id,
          key: index + 1,
          Index: index + 1,
          profile: get(value, "profileImage.url")
            ? get(value, "profileImage.url")
            : "/logo512.png",
          firstName: value.firstName ? value.firstName : "Nan",
          lastName: value.lastName ? value.lastName : "Nan",
          companyName: value.companyName ? value.companyName : "Nan",
          email: get(value, "user.email") ? get(value, "user.email") : "Nan",
          phoneNumber: get(value, "user.phoneNumber")
            ? get(value, "user.phoneNumber")
            : "NaN",
          createdAt: value.createdAt
            ? moment(value.createdAt).format("MM/DD/YYYY")
            : moment("2022-02-25T09:52:07.574Z").format("MM/DD/YYYY"),
          views: value.views ? value.views : 0,
          tags: get(value, "tags", []),
          appleWallet: get(value, "pkpasses", []).length,
          value: value,
          accountName: value.accountName,
          color: value.type === "PROFESSIONAL" ? "teal" : "gray",
        }))
      );
    } else {
      setData(
        accounts
          .filter((item: any) => {
            // console.log(moment(item.createdAt).format("MM/DD/YYYY") > startDate[1])
            return (
              moment(item.createdAt) > moment(startDate[0]) &&
              moment(item.createdAt) < moment(startDate[1])
            );
          })
          .filter(search)
          .map((value: any, index: number) => ({
            _id: value._id,
            key: index + 1,
            Index: index + 1,
            profile: get(value, "profileImage.url")
              ? get(value, "profileImage.url")
              : "/logo512.png",
            firstName: value.firstName ? value.firstName : "Nan",
            lastName: value.lastName ? value.lastName : "Nan",
            companyName: value.companyName ? value.companyName : "Nan",
            email: get(value, "user.email") ? get(value, "user.email") : "Nan",
            phoneNumber: get(value, "user.phoneNumber")
              ? get(value, "user.phoneNumber")
              : "NaN",
            createdAt: value.createdAt
              ? moment(value.createdAt).format("MM/DD/YYYY")
              : moment("2022-02-25T09:52:07.574Z").format("MM/DD/YYYY"),
            views: value.views ? value.views : 0,
            tags: get(value, "tags", []),
            appleWallet: get(value, "pkpasses", []).length,
            value: value,
            accountName: value.accountName,
            color: value.type === "PROFESSIONAL" ? "teal" : "gray",
          }))
      );
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, proUser, startDate, accountType, query]);

  const columns = [
    {
      title: "SR No.",
      width: 80,
      render: (text: string, record: any, index: number) =>
        (page - 1) * paginationSize + index + 1,
    },
    // {
    //   title: "TYPE",
    //   dataIndex: "value",
    //   render: (value: any) => (
    //     <>
    //       <Button
    //         style={{ cursor: "unset" }}
    //         colorScheme={value.type === "PROFESSIONAL" ? "teal" : "gray"}
    //         variant="solid"
    //         // style={{
    //         //   backgroundColor:
    //         //     value.type === "PROFESSIONAL" ? "green" : "black",
    //         // }}
    //       >
    //         {value.type ? value.type : "PERSONAL"}
    //       </Button>
    //     </>
    //   ),
    // },
    {
      title: "ACTION",
      dataIndex: "value",
      width: 180,
      render: (value: any) => (
        <>
          <IconButton
            aria-label="Verify"
            icon={<AiOutlineLink color={"#3e8578"} />}
            marginRight={2}
            onClick={() => {
              Navigate({
                pathname: "/user",
                search: `?${createSearchParams({
                  email: value.user.email,
                })}`,
              });
            }}
          />
          <IconButton
            aria-label="Verify"
            icon={
              <MdVerified
                color={get(value, "isVerify") ? "#3e8578" : "#98a1aa"}
              />
            }
            marginRight={2}
            // variant={"ghost"}
            onClick={() => {
              verifyAccount(value);
            }}
          />
          <IconButton
            aria-label="View Blog"
            icon={<IoMdLogIn color="#3e8578" />}
            onClick={() => {
              getAuthToken(get(value, "user._id"));
            }}
          />
        </>
      ),
    },
    {
      title: "Image",
      dataIndex: "profile",
      render: (data: any, r: any) => (
        <>
          <img src={r.profile} className="imgs" alt="slynkProfile" />
        </>
      ),
    },
    {
      title: "FIRST NAME",
      dataIndex: "firstName",
      sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: "LAST NAME",
      dataIndex: "lastName",
      sorter: (a: any, b: any) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: "URL",
      dataIndex: "accountName",
    },
    {
      title: "COMPANY NAME",
      dataIndex: "companyName",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
    },
    {
      title: "CREATED ON",
      dataIndex: "createdAt",
      width: 150,
      sorter: (a: any, b: any) =>
        moment(a.createdAt, "MM/DD/YYYY").unix() -
        moment(b.createdAt, "MM/DD/YYYY").unix(),
    },
    {
      title: "VIEWS",
      dataIndex: "views",
    },
    {
      title: "TAG",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <div style={{ display: "flex" }}>
            <p>{value.tags.length}</p>
            <button
              className="viewbtn"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                viewEvent(value);
              }}
            >
              <FiEye className="icon" />
            </button>
          </div>
        </>
      ),
    },
    {
      title: "AppleWallet",
      dataIndex: "appleWallet",
    },
    // {
    //   title: "ACTION",
    //   dataIndex: "value",
    //   render: (value: any) => (
    //     <>
    //       <IconButton
    //         aria-label="View Blog"
    //         icon={<IoMdLogIn color="#3e8578" />}
    //         marginRight={2}
    //         onClick={() => {
    //           getAuthToken(get(value, "user._id"));
    //         }}
    //       />
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      {errorMsg ? (
        <Alert status="error" zIndex={100}>
          <AlertIcon />
          <AlertTitle mr={2}>{errorMsg}</AlertTitle>

          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMsg("")}
          />
        </Alert>
      ) : null}

      <div
        style={{
          width: "92vw",
          maxWidth: "92vw",
          height: "95vh",
          top: "8vh ",
          left: "4vw",
          position: "relative",
          paddingBottom: "10px",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            rowKey={(record) => record._id}
            columns={columns}
            pagination={{
              onChange(current, pageSize) {
                setPage(current);
                setPaginationSize(pageSize);
              },
              hideOnSinglePage: true,
              showSizeChanger: true,
            }}
            dataSource={data}
            loading={busy && data.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 4em)", x: 1400 }}
            rowClassName={(record: any) => record.color}
          />
        )}
      </div>

      {/* {isPro && proData && (
        <UserProConfirmation
          isOpen={isProOpen}
          onClose={onProClose}
          account={proData}
        />
      )} */}
      {isVerifyOpen && targetAccount && (
        <AccountVerifyConformation
          isOpen={isVerifyOpen}
          onClose={onVerifyClose}
          account={targetAccount}
        />
      )}
      {isViewOpen && targetAccount && (
        <AccountTagView
          account={targetAccount}
          isOpen={isViewOpen}
          onClose={onViewClose}
        />
      )}
    </>
  );
};

export default AccountTable;
