import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { REQUEST_ACCOUNT_VERIFY } from "../../store/account/accountActionType";
import { useAccounts } from "../../store/account/reducer";
import { IAccount } from "../../types/account";
interface Props {
  account: IAccount;
  isOpen: any;
  onClose: any;
}
const AccountVerifyConformation: React.FC<Props> = ({
  isOpen,
  onClose,
  account,
}) => {
  const dispatch = useDispatch();
  const { busy, message, accounts } = useAccounts();
  const toast = useToast();

  useEffect(() => {
    if (
      !busy &&
      !message &&
      !(accounts || []).find(
        (acc: Record<string, any>) => acc._id === account._id
      )
    ) {
      onClose();
    }
  }, [account._id, accounts, busy, message, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User Verification</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Text color="red">
            {get(account, "isVerify")
              ? "Are sure to  unverify this account ? "
              : "Are sure to verify this account ? "}
          </Text>
          <Text>
            Account belongs to this email : {get(account, "user.email")}
          </Text>
          {message && (
            <Alert status="error" zIndex={100}>
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            onClick={() => {
              if (get(account, "user._id")) {
                dispatch({
                  type: REQUEST_ACCOUNT_VERIFY,
                  payload: {
                    accountId: get(account, "_id"),
                  },
                  onSuccess: () => {
                    toast({
                      title: !get(account, "isVerify")
                        ? `From now ${get(account, "firstName")} ${get(
                            account,
                            "lastName"
                          )} Verified.`
                        : `From now ${get(account, "firstName")} ${get(
                            account,
                            "lastName"
                          )} Unverified.`,
                      status: !get(account, "isVerify") ? "success" : "warning",
                      duration: 3000,
                      position: "top",
                      isClosable: true,
                    });
                    onClose();
                  },
                });
              }
            }}
            disabled={busy}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default AccountVerifyConformation;
