import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_FEEDBACK_MESSAGE_TYPE,
  SET_FEEDBACK_MESSAGE_TYPE,
} from "./feedbackMessageTypeActionTypes";

function* requestFeedbackMessageType(action: Record<string, any>): any {
  try {
    const result: any = yield call(getFeedbackMessageType);
    yield put({ type: SET_FEEDBACK_MESSAGE_TYPE, payload: result.data });
  } catch (err) {
    console.error(`Error fetching feedback`, action);
  }
}
export function getFeedbackMessageType() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/feedback_type`,
    withCredentials: true,
  });
}
const feedbackSaga = function* () {
  yield all([
    takeLatest(REQUEST_FEEDBACK_MESSAGE_TYPE, requestFeedbackMessageType),
  ]);
};
export default feedbackSaga;
