import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_MASTER_TUTORIAL,
  REQUEST_CREATE_MASTER_TUTORIAL,
  REQUEST_UPDATE_MASTER_TUTORIAL,
  ERROR_DELETE_MASTER_TUTORIAL,
  SET_MASTER_TUTORIAL,
  SUCCESS_CREATE_MASTER_TUTORIAL,
  SUCCESS_UPDATE_MASTER_TUTORIAL,
  REQUEST_DELETE_MASTER_TUTORIAL,
  ERROR_GET_MASTER_TUTORIAL,
  ERROR_CREATE_MASTER_TUTORIAL,
  ERROR_UPDATE_MASTER_TUTORIAL,
  SUCCESS_DELETE_MASTER_TUTORIAL,
} from "./tutorialActionTypes";

function* requestMasterTutorial(action: Record<string, any>): any {
  try {
    const result: any = yield call(getTutorialMaster);
    yield put({ type: SET_MASTER_TUTORIAL, payload: result.data });
    // console.warn(result.data);
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_GET_MASTER_TUTORIAL, payload: message });
  }
}

function* requestUpdateMasterTutorial(action: Record<string, any>): any {
  try {
    const result = yield call(
      updateMasterTutorial,
      action.data.payload,
      action.data._id
    );
    yield put({
      type: SUCCESS_UPDATE_MASTER_TUTORIAL,
      payload: result.data,
    });
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_UPDATE_MASTER_TUTORIAL, payload: message });
  }
}

function* requestCreateMasterTutorial(action: Record<string, any>): any {
  try {
    const result = yield call(createMasterTutorial, action.payload);
    yield put({
      type: SUCCESS_CREATE_MASTER_TUTORIAL,
      payload: result.data,
    });
    // update relevant category as well
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_CREATE_MASTER_TUTORIAL, payload: message });
  }
}

function* requestDeleteMasterTutorial(action: Record<string, any>): any {
  try {
    yield call(deleteMasterTutorial, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_DELETE_MASTER_TUTORIAL,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETE_MASTER_TUTORIAL, payload: message });
  }
}

export function getTutorialMaster() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/tutorial`,
    withCredentials: true,
  });
}

export function updateMasterTutorial(
  payload: Record<string, any>,
  _id: string
) {
  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_BASE_URL}/tutorial/${_id}`,
    data: payload,
    withCredentials: true,
  });
}

export function createMasterTutorial(payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/tutorial`,
    data: payload,
    withCredentials: true,
  });
}

export function deleteMasterTutorial(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/tutorial/${_id}`,
    withCredentials: true,
  });
}

const tutorialMasterSaga = function* () {
  yield all([
    takeLatest(REQUEST_MASTER_TUTORIAL, requestMasterTutorial),
    takeLatest(REQUEST_UPDATE_MASTER_TUTORIAL, requestUpdateMasterTutorial),
    takeLatest(REQUEST_CREATE_MASTER_TUTORIAL, requestCreateMasterTutorial),
    takeLatest(REQUEST_DELETE_MASTER_TUTORIAL, requestDeleteMasterTutorial),
  ]);
};

export default tutorialMasterSaga;
