import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  REQUEST_MASTER_INSTRUCTION,
  REQUEST_CREATE_MASTER_INSTRUCTION,
  REQUEST_UPDATE_MASTER_INSTRUCTION,
  ERROR_DELETE_MASTER_INSTRUCTION,
  SET_MASTER_INSTRUCTION,
  SUCCESS_CREATE_MASTER_INSTRUCTION,
  SUCCESS_UPDATE_MASTER_INSTRUCTION,
  REQUEST_DELETE_MASTER_INSTRUCTION,
  ERROR_GET_MASTER_INSTRUCTION,
  ERROR_CREATE_MASTER_INSTRUCTION,
  ERROR_UPDATE_MASTER_INSTRUCTION,
  SUCCESS_DELETE_MASTER_INSTRUCTION,
} from "./instructionActionTypes";

function* requestMasterInstruction(action: Record<string, any>): any {
  try {
    const result: any = yield call(getInstructionMaster);
    yield put({ type: SET_MASTER_INSTRUCTION, payload: result.data });
    // console.warn(result.data);
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_GET_MASTER_INSTRUCTION, payload: message });
  }
}

function* requestUpdateMasterInstruction(action: Record<string, any>): any {
  try {
    const result = yield call(
      updateMasterInstruction,
      action.data.payload,
      action.data._id
    );
    yield put({
      type: SUCCESS_UPDATE_MASTER_INSTRUCTION,
      payload: result.data,
    });
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_UPDATE_MASTER_INSTRUCTION, payload: message });
  }
}

function* requestCreateMasterInstruction(action: Record<string, any>): any {
  try {
    const result = yield call(createMasterInstruction, action.payload);
    yield put({
      type: SUCCESS_CREATE_MASTER_INSTRUCTION,
      payload: result.data,
    });
    // update relevant category as well
  } catch (error: any) {
    console.log(error);
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = error.response.data.message || "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_CREATE_MASTER_INSTRUCTION, payload: message });
  }
}

function* requestDeleteMasterInstruction(action: Record<string, any>): any {
  try {
    yield call(deleteMasterInstruction, get(action, "payload._id", ""));
    yield put({
      type: SUCCESS_DELETE_MASTER_INSTRUCTION,
      payload: get(action, "payload._id", ""),
    });
  } catch (error: any) {
    let message =
      "Something went wrong, please try again after some time or Refresh the Page.";
    if (get(error, "response.status") === 500) {
      message = "Something happened wrong try again after sometime.";
    } else if (get(error, "response.status") === 422) {
      message = "please provide valid contain";
    } else if (get(error, "response.status") === 415) {
      message = error.response.data.message;
    }
    yield put({ type: ERROR_DELETE_MASTER_INSTRUCTION, payload: message });
  }
}

export function getInstructionMaster() {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}/admin/instruction`,
    withCredentials: true,
  });
}

export function updateMasterInstruction(
  payload: Record<string, any>,
  _id: string
) {
  return axios({
    method: "patch",
    url: `${process.env.REACT_APP_BASE_URL}/admin/instruction/${_id}`,
    data: payload,
    withCredentials: true,
  });
}

export function createMasterInstruction(payload: Record<string, any>) {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/admin/instruction`,
    data: payload,
    withCredentials: true,
  });
}

export function deleteMasterInstruction(_id: string) {
  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/admin/instruction/${_id}`,
    withCredentials: true,
  });
}

const instructionMasterSaga = function* () {
  yield all([
    takeLatest(REQUEST_MASTER_INSTRUCTION, requestMasterInstruction),
    takeLatest(
      REQUEST_UPDATE_MASTER_INSTRUCTION,
      requestUpdateMasterInstruction
    ),
    takeLatest(
      REQUEST_CREATE_MASTER_INSTRUCTION,
      requestCreateMasterInstruction
    ),
    takeLatest(
      REQUEST_DELETE_MASTER_INSTRUCTION,
      requestDeleteMasterInstruction
    ),
  ]);
};

export default instructionMasterSaga;
