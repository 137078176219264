import {
  Alert,
  AlertTitle,
  Button,
  Drawer,
  DrawerBody,
  IconButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { get } from "lodash";
import * as Yup from "yup";
import { FormField } from "../form/formField/FormField";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { MdModeEditOutline } from "react-icons/md";
import { IFaq } from "../../types/faq";
import { useFaqsMaster } from "../../store/faq/reducer";
import {
  REQUEST_CREATE_MASTER_FAQ,
  REQUEST_UPDATE_MASTER_FAQ,
} from "../../store/faq/faqActionTypes";
import { FormFieldEditor } from "../form/formField/FormFieldEditor";
interface Props {
  faq?: IFaq;
  isOpen: any;
  onClose: any;
}

// const FILE_SIZE = 3 * 1024 * 1024;

// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const addSchema = Yup.object({
  title: Yup.string().required("Required"),
  embeddedLink: Yup.string().optional(),
  description: Yup.string().optional(),
});

const FaqMasterEditDrawer: React.FC<Props> = ({ faq, isOpen, onClose }) => {
  const { masterFaqs, busy, message } = useFaqsMaster();

  const dispatch = useDispatch();

  const initialValue = useMemo(() => {
    if (faq) {
      return {
        title: faq.title,
        embeddedLink: faq.embeddedLink,
        description: faq.description,
      };
    }
    return {
      title: "",
      embeddedLink: "",
      description: "",
    };
  }, [faq]);

  const onSubmit = (values: Record<string, any>) => {
    let payload: Record<string, any> = {
      title: values.title,
      embeddedLink: values.embeddedLink,
      description: values.description,
    };

    if (faq) {
      const data = { payload, _id: faq._id };
      dispatch({ type: REQUEST_UPDATE_MASTER_FAQ, data });
    } else {
      dispatch({ type: REQUEST_CREATE_MASTER_FAQ, payload });
    }
  };

  const toast = useToast();
  const faqs = useMemo(() => {
    return masterFaqs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (faq) {
      const linkFromStore = masterFaqs.find((ml: any) => ml._id === faq._id);
      if (linkFromStore !== faq) {
        toast({
          title: "Success.",
          description: "Link is updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        onClose();
      }
    } else if (faqs.length !== masterFaqs.length) {
      toast({
        title: "Success.",
        description: "Link is created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [masterFaqs, faqs, onClose, faq, toast]);

  const [isDisabled, setDisabled] = useState(!!(faq && faq._id));

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, submitForm, setFieldValue }) => (
        <Form>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size={"md"}
            onClose={onClose}
            closeOnOverlayClick={busy}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                <Flex>
                  {get(faq, "title") || "Add faq"}
                  <Spacer></Spacer>
                  {faq && faq._id && (
                    <IconButton
                      aria-label="update link"
                      key={"updateLinkButton"}
                      alignSelf={"end"}
                      onClick={() => {
                        setDisabled(!isDisabled);
                      }}
                      icon={<MdModeEditOutline />}
                    ></IconButton>
                  )}
                </Flex>

                {message && (
                  <Alert status="error">
                    <AlertTitle>{message}</AlertTitle>
                  </Alert>
                )}
              </DrawerHeader>
              <DrawerBody>
                <FormField
                  required={true}
                  disabled={isDisabled}
                  name="Name : "
                  fieldName="title"
                  type="text"
                  error={touched.title ? (errors.title as string) : undefined}
                />

                <FormField
                  disabled={isDisabled}
                  required={false}
                  name="Embedded link : "
                  fieldName="embeddedLink"
                  type="text"
                  error={
                    errors.embeddedLink
                      ? (errors.embeddedLink as string)
                      : undefined
                  }
                />
                {/* <FormFieldTextArea
                  disabled={isDisabled}
                  required={false}
                  name="Description : "
                  fieldName="description"
                  type="text"
                  error={
                    errors.description
                      ? (errors.description as string)
                      : undefined
                  }
                /> */}

                <FormFieldEditor
                  disabled={isDisabled}
                  required={true}
                  name="Description : "
                  fieldName="description"
                  type="textarea"
                  error={
                    touched.description
                      ? (errors.description as string)
                      : undefined
                  }
                />
              </DrawerBody>
              <DrawerFooter backgroundColor={"gray.100"}>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={onClose}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={submitForm}
                  disabled={busy || isDisabled}
                >
                  {faq && faq._id ? "Update" : "Save"}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};
export default FaqMasterEditDrawer;
