import { Spin, Table } from "antd";
import { get as _get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useReportMaster } from "../../store/report/reducer";
import { IReport } from "../../types/report";

interface Props {
  query: string;
}
const ReportMasterTable: React.FC<Props> = ({ query }) => {
  const { masterReport, busy } = useReportMaster();

  const searchColumns = useMemo(
    () => [
      "title",
      "description",
      "reportBy.email",
      "reportBy.phoneNumber",
      "reportedAccount.firstName",
      "reportedAccount.lastName",
      "reportedAccount.type",
      "reportedAccount.user.phoneNumber",
      "reportedAccount.user.email",
    ],
    []
  );

  const search = (account: Record<string, any>) => {
    return searchColumns.some((column) => {
      return (
        _get(account, column, "")
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    });
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(
      masterReport.filter(search).map((value: IReport, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        type: _get(value, "reportedAccount.type"),
        title: value.title,
        description: value.description,
        email: _get(value, "reportBy.email"),
        email1: _get(value, "reportedAccount.user.email")
          ? _get(value, "reportedAccount.user.email")
          : "N/A",
        phoneNumber: _get(value, "reportBy.phoneNumber"),
        phoneNumber1: _get(value, "reportedAccount.user.phoneNumber")
          ? _get(value, "reportedAccount.user.phoneNumber")
          : "N/A",
        value: value,
      }))
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterReport, query]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
    },
    {
      title: "TITLE",
      dataIndex: "title",
    },
    {
      title: "TYPE",
      dataIndex: "type",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      width: "26vw",
    },
    {
      title: "REPORTED BY",
      children: [
        {
          title: "EMAIL",
          dataIndex: "email",
        },
        {
          title: "PHONE NUMBER",
          dataIndex: "phoneNumber",
        },
      ],
    },
    {
      title: "REPORTED USER",
      children: [
        {
          title: "EMAIL",
          dataIndex: "email1",
        },
        {
          title: "PHONE NUMBER",
          dataIndex: "phoneNumber1",
        },
      ],
    },
  ];

  return (
    <>
      <div
        style={{
          width: "92vw",
          maxWidth: "92vw",
          height: "90vh",
          top: "10vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && masterReport.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 8em)", x: 1300 }}
            rowClassName={() => "gray"}
          />
        )}
      </div>
    </>
  );
};

export default ReportMasterTable;
