import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { get } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useUsers } from "../../store/user/reducer";
import { PRO_USER_REQUEST } from "../../store/user/userActionType";
import { IUser } from "../../types/user";
import * as Yup from "yup";
import { FormFieldCalender } from "../form/formField/FormFieldCalender";

interface Props {
  user: IUser;
  isOpen: any;
  onClose: any;
}

const addSchema = Yup.object({
  subscriptionTill: Yup.number()
    .optional()
    .nullable(true)
    // checking self-equality works for NaN, transforming it to null
    .transform((_, val) => (val === Number(val) ? val : null)),
});

const UserProConfirmation: React.FC<Props> = ({ isOpen, onClose, user }) => {
  const dispatch = useDispatch();
  const { busy, message, users } = useUsers();
  const toast = useToast();

  useEffect(() => {
    if (
      !busy &&
      !message &&
      !(users || []).find((acc: Record<string, any>) => acc._id === user._id)
    ) {
      onClose();
    }
  }, [user._id, users, busy, message, onClose]);
  const initialValue = useMemo(() => {
    return {
      subscriptionTill: user.subscriptionTill,
    };
  }, [user]);

  const onSubmit = (values: Record<string, any>) => {
    const data = {
      userId: user._id,
      payload: {
        subscriptionTill: user.isPro
          ? null
          : values.subscriptionTill
          ? new Date(values.subscriptionTill)
          : null,
        isAdminPro: !user.isPro,
      },
    };
    dispatch({
      type: PRO_USER_REQUEST,
      data,
      onSuccess: () => {
        toast({
          title: !get(user, "isPro")
            ? `From now ${get(user, "email")} can use pro features`
            : `From now ${get(user, "email")} can not use pro features`,
          status: !get(user, "isPro") ? "success" : "warning",
          duration: 3000,
          position: "top",
          isClosable: true,
        });
        onClose();
      },
    });
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, submitForm }) => (
        <Form>
          <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>User Pro</ModalHeader>
              <ModalCloseButton disabled={busy} />
              <ModalBody>
                {!get(user, "isPro") ? (
                  <>
                    <Text color="red">
                      {get(user, "isPro")
                        ? "Are sure to remove pro User ? "
                        : "Are sure to make pro User ? "}
                    </Text>
                    <Text> {get(user, "email")}</Text>
                    {message && (
                      <Alert status="error" zIndex={100}>
                        <AlertIcon />
                        <AlertTitle mr={2}>{message}</AlertTitle>
                      </Alert>
                    )}

                    <FormFieldCalender
                      value={values["subscriptionTill"]}
                      required={true}
                      name={"Subscription Till : "}
                      fieldName={"subscriptionTill"}
                      placeholder={"Select a Date"}
                      error={
                        touched.subscriptionTill
                          ? (errors.subscriptionTill as string)
                          : undefined
                      }
                    />
                  </>
                ) : (
                  <>
                    <Text color="red">
                      {get(user, "isPro")
                        ? "Are sure to remove pro User ? "
                        : "Are sure to make pro User ? "}
                    </Text>
                    <Text> {get(user, "email")}</Text>
                  </>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={onClose}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="red"
                  variant={"outline"}
                  mr={3}
                  onClick={submitForm}
                  disabled={busy}
                >
                  Submit
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
export default UserProConfirmation;
