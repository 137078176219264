import React, { useEffect } from "react";
import { Button, Center, IconButton, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

import { useFaqsMaster } from "../../store/faq/reducer";
import { IFaq } from "../../types/faq";
import { useDispatch } from "react-redux";
import { REQUEST_MASTER_FAQ } from "../../store/faq/faqActionTypes";
import { IoMdTrash } from "react-icons/io";
import FaqMasterEditDrawer from "../drawer/FaqMasterEditDrawer";
import FaqDeleteConfirmation from "../modals/FaqDeleteConfirmation";
import { Spin, Table } from "antd";

const FaqTable: React.FC = () => {
  const { masterFaqs, busy } = useFaqsMaster();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!busy && !(masterFaqs || []).length) {
      dispatch({ type: REQUEST_MASTER_FAQ });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [targetItem, setTargetItem] = useState<IFaq>();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const viewItem = (item: IFaq) => {
    setTargetItem(item);
    onViewOpen();
  };

  const deleteItem = (item: IFaq) => {
    setTargetItem(item);
    onDeleteOpen();
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(
      masterFaqs.map((value: IFaq, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        title: value.title,
        embeddedLink: value.embeddedLink,
        // description:
        //   value.description && value.description.length > 20
        //     ? `${value.description?.slice(0, 20)}...`
        //     : value.description,
        // description: value.description
        //   ? value.description.toString().slice(0, 30)
        //   : "Nan",
        value: value,
      }))
    );
    setLoading(false);
  }, [masterFaqs]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
    },
    {
      title: "TITLE",
      dataIndex: "title",
    },
    {
      title: "EMBEDDED LINK",
      dataIndex: "value",
      render: (value: IFaq) => (
        <>
          {value.embeddedLink || value.embeddedLink !== "" ? (
            <Button
              onClick={() => {
                window.open(value.embeddedLink, "_blank");
              }}
            >
              Click to Open
            </Button>
          ) : (
            <Center>NaN</Center>
          )}
        </>
      ),
    },
    // {
    //   title: "DESCRIPTION",
    //   dataIndex: "description",
    // },
    {
      title: "ACTION",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Button
            onClick={() => {
              viewItem(value);
            }}
          >
            View
          </Button>
          <IconButton
            aria-label="delete link"
            key={"deleteLinkButton"}
            marginLeft={2}
            onClick={() => {
              deleteItem(value);
            }}
            icon={<IoMdTrash />}
          ></IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      {/* <div
        style={{
          // overflow: "scroll",
          // width: "90vw",
          // maxWidth: "90vw",
          // height: "85vh",
          // maxHeight: "85vh",
          // top: "10vh ",
          // left: "5vw",
          // position: "relative",
          // paddingRight: "50px",
          marginBottom: "50px",
          // overflow: "scroll",
          width: "50vw",
          maxWidth: "50vw",
          top: "10vh ",
          left: "5vw",
          position: "relative",
          paddingBottom: "100px",
        }}
      >
        <Table variant="simple">
          <TableCaption>Faq Details</TableCaption>
          <Thead
            key={"Table-header-body"}
            style={{
              position: "sticky",
              top: "0vh ",
              backgroundColor: "#fff",
              zIndex: "100",
            }}
          >
            <Th key={"sr No"}>Sr. No.</Th>
            <Th key={"title"}>Title</Th>
            <Th key={"Embedded Link"}>Embedded Link</Th>
            <Th key={"Description"}>Description</Th>
            <Th key={"View"}></Th>
            <Th key={"delete"}></Th>
          </Thead>

          {isArray(masterFaqs) && masterFaqs.length ? (
            <Tbody>
              {masterFaqs.map((value: IFaq, index: number) => {
                return (
                  <Tr key={value._id}>
                    <Td key={"Serial number"}>{index + 1}</Td>
                    <Td key={"title"}>{value.title}</Td>
                    <Td key={"embeddedLink"}>
                      <Button
                        onClick={() => {
                          window.open(value.embeddedLink, "_blank");
                        }}
                      >
                        Click to Open
                      </Button>
                    </Td>
                    <Td key={"description"}>
                      {value.description && value.description.length > 20
                        ? `${value.description?.slice(0, 20)}...`
                        : value.description}
                    </Td>
                    <Td
                      key={"edit_link"}
                      onClick={() => {
                        viewItem(value);
                      }}
                    >
                      <Button aria-label="Show">View</Button>
                    </Td>
                    <Td key={"delete"}>
                      <IconButton
                        aria-label="delete link"
                        key={"deleteLinkButton"}
                        marginLeft={2}
                        onClick={() => {
                          deleteItem(value);
                        }}
                        icon={<IoMdTrash />}
                      ></IconButton>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Tbody>
              <Tr>
                <Td colSpan={15} textAlign={"center"} alignItems={"center"}>
                  Loading...
                </Td>
              </Tr>
            </Tbody>
          )}
        </Table>
      </div> */}
      <div
        style={{
          width: "92vw",
          maxWidth: "92vw",
          height: "78vh",
          top: "10vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && data.length === 0 ? true : false}
            scroll={{ y: "calc(60vh - 4em)", x: 1300 }}
            rowClassName={() => "gray"}
          />
        )}
      </div>
      {isViewOpen && (
        <FaqMasterEditDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          faq={targetItem}
        />
      )}

      {isDeleteOpen && targetItem && (
        <FaqDeleteConfirmation
          faq={targetItem}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
    </>
  );
};

export default FaqTable;
