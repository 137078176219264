export const REQUEST_FEEDBACK_LIST = "REQUEST_FEEDBACK_LIST";
export const SET_FEEDBACK_LIST = "SET_FEEDBACK_LIST";

export const REQUEST_SEND_ADMIN_FEEDBACK = "REQUEST_SEND_ADMIN_FEEDBACK";
export const ERROR_SEND_ADMIN_FEEDBACK = "ERROR_SEND_ADMIN_FEEDBACK";
export const SUCCESS_REPLY_SEND = "SUCCESS_REPLY_SEND";

export const REQUEST_UPDATE_FEEDBACK_DETAIL = "REQUEST_UPDATE_FEEDBACK_DETAIL";
export const ERROR_UPDATE_FEEDBACK_DETAIL = "ERROR_UPDATE_FEEDBACK_DETAIL";
export const SUCCESS_UPDATE_FEEDBACK_DETAIL = "SUCCESS_UPDATE_FEEDBACK_DETAIL";
