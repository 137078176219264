import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Input,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import { useAnalyticsMaster } from "../../store/analytics/reducer";
import { REQUEST_MASTER_ANALYTICS } from "../../store/analytics/analyticsActionTypes";
import AnalyticsMasterTable from "../../componenets/AnalyticsMasterTable/AnalyticsMasterTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Analytics = () => {
  const dispatch = useDispatch();
  const { masterAnalytics, busy } = useAnalyticsMaster();
  const [date, setDate] = useState<any>(["", ""]);
  const [confirmDate, setconfirmDate] = useState<any>(["", ""]);
  const [startDates, endDate] = date;
  // console.log(date);

  useEffect(() => {
    if (!busy && !(masterAnalytics || []).length) {
      dispatch({ type: REQUEST_MASTER_ANALYTICS });
    }
  }, []);
  const [searchQuery, setSearch] = useState("");

  return (
    <>
      <MobileView>
        <VStack top={5} position="relative">
          <Heading mt="6px" size="lg" fontWeight="semibold" color="black.400">
            Analytics
          </Heading>
          <Heading size="md" fontWeight="semibold">
            <Center>
              <Input
                id="search"
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              ></Input>
            </Center>
          </Heading>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative" key={"vStack"}>
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Analytics
            </Heading>
            <Spacer></Spacer>
            <HStack>
              <Box>
                <DatePicker
                  className="customDatePicker"
                  selectsRange={true}
                  startDate={startDates}
                  endDate={endDate}
                  onChange={(e: any) => {
                    const newDate = e.map((val: any) => {
                      return moment(val).format("MM/DD/YYYY");
                    });
                    setDate(e ? e : "");
                    setconfirmDate(e ? newDate : "");
                    // console.log(e[0] && e[1] ? "er" : "qw");
                    if (e[0] && e[1]) {
                      dispatch({
                        type: REQUEST_MASTER_ANALYTICS,
                        payload: {
                          startDate: e[0],
                          endDate: e[1],
                        },
                      });
                    }else if(!e[0] && !e[1]){
                      dispatch({
                        type: REQUEST_MASTER_ANALYTICS,
                      });
                    }
                  }}
                  placeholderText={`📆 Select Date`}
                  isClearable={true}
                  // dropdownMode="select"
                  maxDate={new Date()}
                />
              </Box>
            </HStack>
          </Flex>
        </VStack>
      </BrowserView>
      <AnalyticsMasterTable query={searchQuery} confirmDate={confirmDate} />
    </>
  );
};

export default Analytics;
