import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    REQUEST_MASTER_REPORT,
    SET_MASTER_REPORT,
    ERROR_GET_MASTER_REPORT,
} from "./reportActionTypes"

function* requestMasterReport(action: Record<string, any>): any {
    try {
        const result: any = yield call(getReportMaster);
        yield put({ type: SET_MASTER_REPORT, payload: result.data });
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_GET_MASTER_REPORT, payload: message });
    }
}


export function getReportMaster() {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/report`,
        withCredentials: true,
    });
}


const reportMasterSaga = function* () {
    yield all([
        takeLatest(REQUEST_MASTER_REPORT, requestMasterReport),

    ])
}

export default reportMasterSaga;