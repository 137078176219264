import {
  Center,
  Flex,
  Heading,
  HStack,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PromoCodeEditDrawer from "../../componenets/drawer/PromoCodeEditDrawer";
import PromoCodeMasterTable from "../../componenets/PromoCodeMasterTable/PromoCodeMasterTable";
import { REQUEST_MASTER_COUPON } from "../../store/coupon/couponActionType";
import { useCouponsMaster } from "../../store/coupon/reducer";
import { REQUEST_MASTER_PROMOCODE } from "../../store/promoCode/promoCodeActionTypes";
import { usePromoCodeMaster } from "../../store/promoCode/reducer";
import { BrowserView, MobileView } from "react-device-detect";

const PromoCode = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { masterPromoCode, busy: busyPromoCode } = usePromoCodeMaster();
  const { masterCoupons, busy: busyCoupon } = useCouponsMaster();

  useEffect(() => {
    // if (!busyCoupon && !(masterCoupons || []).length) {
    dispatch({ type: REQUEST_MASTER_COUPON });
    // }
    // if (!busyPromoCode && !(masterPromoCode || []).length) {
    dispatch({ type: REQUEST_MASTER_PROMOCODE });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <MobileView>
        <VStack top={5} position="relative">
          <Heading size="lg" fontWeight="semibold" color="black.400">
            Promo Code
          </Heading>

          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <HStack>
              <Center
                bg="tomato"
                h="100%"
                color="white"
                p={3}
                onClick={() => {
                  onOpen();
                  //   setaddErrorMsg("");
                }}
                style={{ cursor: "pointer" }}
              >
                + Add Promo Code
              </Center>
            </HStack>
          </Heading>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative">
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Promo Code
            </Heading>

            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <HStack>
                <Center
                  bg="tomato"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Promo Code
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </BrowserView>
      <PromoCodeMasterTable />
      {isOpen && <PromoCodeEditDrawer isOpen onClose={onClose} />}
    </>
  );
};

export default PromoCode;
