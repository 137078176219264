import { useSelector } from "react-redux";
import { IUser } from "../../types/user";

import {
  REQUEST_AUTH,
  REQUEST_SESSION,
  SET_USER,
  LOGOUT_USER,
  REQUEST_LOGOUT_USER,
} from "./authActionType";

const initialState: { busy: boolean; message: string; adminUser: IUser } = {
  busy: false,
  message: "",
  //@ts-ignore
  adminUser: {},
};

const auth = (state = initialState, action: Record<string, any>) => {
  switch (action.type) {
    case REQUEST_AUTH:
    case REQUEST_LOGOUT_USER:
      return {
        ...state,
        busy: true,
        message: "",
      };
    case REQUEST_SESSION:
      return {
        ...state,
        busy: true,
      };

    case SET_USER:
      return {
        ...state,
        busy: false,
        adminUser: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        busy: false,
        message: "",
        adminUser: {},
      };
    default:
      return state;
  }
};

export default auth;
export function useAuth() {
  return useSelector((state: Record<string, any>) => state.auth);
}
