import { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import { Form, Formik } from "formik";
import style from "./TagMasterTable.module.css";
import { FormField } from "../form/formField/FormField";
import Select from "react-select";
import {
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  Switch,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { ITag } from "../../types/tags";
import { useTagsMaster } from "../../store/tag/reducer";
import { useDispatch } from "react-redux";
import {
  REQUEST_MASTER_TAG,
  REQUEST_SORTED_TAG,
} from "../../store/tag/tagActionTypes";
import { BrowserView, MobileView } from "react-device-detect";
import moment from "moment";

interface Props {
  // isCustomTag: boolean;
}
const TagMasterTable: React.FC<Props> = () => {
  const { masterTags, busy, tagsCount } = useTagsMaster();
  // console.log("masterTags", masterTags);
  const [isSearchData, setIsSearchData] = useState(false);
  const [csvData, setCSVData] = useState<any>([]);

  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    changeData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeData = (page: any) => {
    if (!busy) {
      dispatch({
        type: REQUEST_MASTER_TAG,
        data: { page },
        onSuccess: () => {
          setLoading(false);
        },
      });
    }
  };

  const colorObject: any = useMemo(() => {
    return {
      BK: "Black",
      WT: "White",
      HG: "Hologram",
      QR: "QRDesign",
      MBK: "Matte Black",
      MWT: "Matte White",
      MHG: "Matte Hologram",
    };
  }, []);

  const typeObject: any = useMemo(() => {
    return {
      PC: "PVC Card",
      MC: "Metal Card",
      BMC: "Blank Metal",
      RE: "Round Epoxy",
      SE: "Square Epoxy",
      ST: "SQUARE TAG ( PLASTIC )",
      RT: "ROUND TAG ( PLASTIC )",
      CC: "CUSTOM CARD",
    };
  }, []);

  useEffect(() => {
    setDisplayData(
      masterTags
        // .filter((item: ITag) => !!item.isCustomTag === isCustomTag)
        .map((value: ITag, index: number) => ({
          _id: value._id,
          key: index + 1,
          index: index + 1,
          color:
            value.color && colorObject[value.color]
              ? colorObject[value.color]
              : "none",
          type:
            value.type && typeObject[value.type]
              ? typeObject[value.type]
              : "none",
          random: value.random,
          batchNo: value.batchNo,
          size: value.size ? value.size : "none",
          urlName: `${process.env.REACT_APP_FRONTEND_URL}/${value.urlName}`,
          block: value.block ? "Blocked" : "Unblocked",
          paired: value.account ? "Paired" : "Unpaired",
        }))
    );
    setLoading(false);
    setCSVData(
      masterTags
        // .filter((item: ITag) => !!item.isCustomTag === isCustomTag)
        .map((value: ITag) => {
          // const data = { ...value };
          // delete data._id;
          // delete data.tagImage;
          // delete data.tagGif;
          // delete data.account;
          // delete data.updatedAt;
          // delete data.password;
          // delete data.label;
          // delete data.block;
          // delete data.size;
          // delete data.color;
          // delete data.batchNo;
          // delete data.type;
          // delete data.random;
          // delete data.blockMessage;

          return {
            serialNumber: value.urlName,
            URL:
              value.createdAt &&
              moment(value.createdAt).isAfter(moment("2023-02-01"))
                ? `${process.env.REACT_APP_FRONTEND_URL}/t/${value.urlName}`
                : `${process.env.REACT_APP_FRONTEND_URL}/${value.urlName}`,
          };
        })
    );
  }, [colorObject, masterTags, typeObject]);

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "index",
      width: 80,
    },
    {
      title: "Paired",
      dataIndex: "paired",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Color",
      dataIndex: "color",
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Random No.",
      dataIndex: "random",
    },
    {
      title: "Url Name",
      dataIndex: "urlName",
      width: 250,
      render: (value: any) => (
        <>
          <a href={value} target="_blank">
            {value}
          </a>
        </>
      ),
    },
    {
      title: "Block",
      dataIndex: "block",
    },
  ];

  const typeOptions: any[] = useMemo(() => {
    return [
      { value: "PC", label: "PVC CARDS" },
      { value: "MC", label: "METAL CARDS" },
      // { value: "BMC", label: "BLANK METAL CARDS" },
      { value: "RE", label: "ROUND EPOXY" },
      { value: "SE", label: "SQUARE EPOXY" },
      // { value: "ST", label: "SQUARE TAG ( PLASTIC )" },
      // { value: "RT", label: "ROUND TAG ( PLASTIC )" },
      { value: "CC", label: "CUSTOM CARD" },
    ];
  }, []);

  const colorOptions: any[] = useMemo(() => {
    return [
      { value: "BK", label: "BLACK" },
      { value: "WT", label: "WHITE" },
      { value: "HG", label: "HOLOGRAM" },
      { value: "MBK", label: "MATTE BLACK" },
      { value: "MWT", label: "MATTE WHITE" },
      { value: "MHG", label: "MATTE HOLOGRAM" },
    ];
  }, []);
  const roundEpoxyOptions: any[] = useMemo(() => {
    return [
      { value: "BK", label: "BLACK" },
      { value: "WT", label: "WHITE" },
      { value: "HG", label: "HOLOGRAM" },
    ];
  }, []);
  const pvcCardOptions: any[] = useMemo(() => {
    return [
      { value: "MBK", label: "MATTE BLACK" },
      { value: "MWT", label: "MATTE WHITE" },
      { value: "MHG", label: "MATTE HOLOGRAM" },
    ];
  }, []);
  const whiteOptions: any[] = useMemo(() => {
    return [{ value: "WT", label: "WHITE" }];
  }, []);

  const initialValue = useMemo(() => {
    return {
      size: "",
      type: "",
      color: "",
      batchNo: "",
      isCustomTag: false,
    };
  }, []);

  // useEffect(() => {
  //   dispatch({
  //     type: REQUEST_SORTED_TAG,
  //     data: { isCustomTag },
  //     onSuccess: () => {
  //       setLoading(false);
  //       setIsSearchData(true);
  //     },
  //   });
  // }, [isCustomTag]);

  const onSubmit = (values: Record<string, any>) => {
    setLoading(true);

    let payload = {
      size: values.size !== "" ? values.size : undefined,
      batchNo: values.batchNo !== "" ? values.batchNo : undefined,
      color: values.color !== null ? values.color.value : undefined,
      type: values.type !== null ? values.type.value : undefined,
      // isCustomTag: values.isCustomTag !== null ? values.isCustomTag : false,
    };

    dispatch({
      type: REQUEST_SORTED_TAG,
      data: payload,
      onSuccess: () => {
        setLoading(false);
        setIsSearchData(true);
      },
    });
  };

  return (
    <>
      <div>
        <Formik
          initialValues={initialValue}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, errors, touched, submitForm }) => (
            <Form>
              <MobileView>
                <div className={style.main}>
                  <Flex style={{ margin: "20px 30px 0 30px ", gap: "9px" }}>
                    <FormControl style={{ textAlign: "start" }}>
                      <Flex width="100%">
                        <HStack>
                          <FormLabel mb={2}>Type : </FormLabel>
                          <Spacer></Spacer>
                        </HStack>
                      </Flex>

                      <Select
                        value={values["type"]}
                        name="type"
                        isClearable
                        onChange={(event) => {
                          //@ts-ignore
                          setFieldValue("type", event);
                        }}
                        options={typeOptions}
                        placeholder="Select a Type"
                      />
                    </FormControl>

                    <FormControl style={{ textAlign: "start" }}>
                      <Flex width="100%">
                        <HStack>
                          <FormLabel mb={2}>Color : </FormLabel>
                          <Spacer></Spacer>
                        </HStack>
                      </Flex>

                      <Select
                        value={values["color"]}
                        isClearable
                        name="color"
                        onChange={(event) => {
                          //@ts-ignore
                          setFieldValue("color", event);
                        }}
                        options={
                          //@ts-ignore
                          values.type && values.type.value === "RE"
                            ? roundEpoxyOptions
                            : //@ts-ignore
                            (values.type && values.type.value === "PC") ||
                              //@ts-ignore
                              values.type.value === "MC"
                            ? pvcCardOptions
                            : //@ts-ignore
                            values.type && values.type.value === "SE"
                            ? //@ts-ignore
                              // values.type.value === "CC"
                              whiteOptions
                            : colorOptions
                        }
                        placeholder="Select a color"
                      />
                    </FormControl>
                  </Flex>
                  <div className={style.flex}>
                    <FormField
                      required={false}
                      // disabled={isDisabled}
                      placeholder="Enter Batch No."
                      name="Batch No. : "
                      fieldName="batchNo"
                      type="text"
                      error={
                        touched.batchNo ? (errors.batchNo as string) : undefined
                      }
                    />

                    <FormField
                      required={false}
                      name="Size : "
                      placeholder="Enter Size"
                      fieldName="size"
                      type="text"
                      error={touched.size ? (errors.size as string) : undefined}
                    />
                    {/* <FormControl style={{ textAlign: "start" }}>
                      <Flex width="100%">
                        <HStack>
                          <FormLabel mb={3}>Custom : </FormLabel>
                          <Spacer></Spacer>
                        </HStack>
                      </Flex>

                      <Switch
                        value={values.isCustomTag ? "true" : "false"}
                        name={"ss"}
                        id="email-alerts"
                        defaultChecked={values.isCustomTag}
                        onChange={() => {
                          setFieldValue("isCustomTag", !values.isCustomTag);
                        }}
                      />
                    </FormControl> */}
                    <div>
                      <button type="submit" className={style.btn}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </MobileView>
              <BrowserView>
                <div className={style.flex}>
                  <FormControl style={{ textAlign: "start" }}>
                    <Flex width="100%">
                      <HStack>
                        <FormLabel mb={3}>Type : </FormLabel>
                        <Spacer></Spacer>
                      </HStack>
                    </Flex>

                    <Select
                      value={values["type"]}
                      name="type"
                      isClearable
                      onChange={(event) => {
                        setFieldValue("type", event);
                      }}
                      options={typeOptions}
                      placeholder="Select a Type"
                    />
                  </FormControl>

                  <FormControl style={{ textAlign: "start" }}>
                    <Flex width="100%">
                      <HStack>
                        <FormLabel mb={3}>Color : </FormLabel>
                        <Spacer></Spacer>
                      </HStack>
                    </Flex>

                    <Select
                      value={values["color"]}
                      isClearable
                      name="color"
                      onChange={(event) => {
                        setFieldValue("color", event);
                      }}
                      options={
                        //@ts-ignore
                        values.type && values.type.value === "RE"
                          ? roundEpoxyOptions
                          : //@ts-ignore
                          (values.type && values.type.value === "PC") ||
                            //@ts-ignore
                            values.type.value === "MC"
                          ? pvcCardOptions
                          : //@ts-ignore
                          values.type && values.type.value === "SE"
                          ? //@ts-ignore
                            // values.type.value === "CC"
                            whiteOptions
                          : colorOptions
                      }
                      placeholder="Select a color"
                    />
                  </FormControl>

                  <FormField
                    required={false}
                    // disabled={isDisabled}
                    placeholder="Enter Batch No."
                    name="Batch No. : "
                    fieldName="batchNo"
                    type="text"
                    error={
                      touched.batchNo ? (errors.batchNo as string) : undefined
                    }
                  />

                  <FormField
                    required={false}
                    name="Size : "
                    placeholder="Enter Size"
                    fieldName="size"
                    type="text"
                    error={touched.size ? (errors.size as string) : undefined}
                  />

                  {/* <FormControl style={{ textAlign: "start" }}>
                    <Flex width="100%">
                      <HStack>
                        <FormLabel mb={3}>Custom : </FormLabel>
                        <Spacer></Spacer>
                      </HStack>
                    </Flex>

                    <Switch
                      value={values.isCustomTag ? "true" : "false"}
                      name={"ss"}
                      id="email-alerts"
                      defaultChecked={values.isCustomTag}
                      onChange={() => {
                        setFieldValue("isCustomTag", !values.isCustomTag);
                      }}
                    />
                  </FormControl> */}
                  <div>
                    <button type="submit" className={style.btn}>
                      Search
                    </button>
                  </div>
                </div>
              </BrowserView>
            </Form>
          )}
        </Formik>
        <div className={style.center}>
          <button
            className={style.btn1}
            onClick={() => {
              setLoading(true);
              setIsSearchData(false);
              changeData(1);
            }}
          >
            Show All
          </button>
          <CSVLink data={csvData} filename="tag">
            <button className={style.btn1}>Excel</button>
          </CSVLink>
        </div>
      </div>
      <div
        style={{
          width: "92vw",
          height: "88vh",
          maxWidth: "92vw",
          top: "4vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {!isSearchData ? (
          <Table
            columns={columns}
            dataSource={displayData}
            loading={loading}
            rowClassName={() => "gray"}
            pagination={{
              showSizeChanger: false,
              total: tagsCount,
              pageSize: 100,
              defaultCurrent: 1,
              onChange: (page) => {
                changeData(page);
              },
              // current: 1,
            }}
            scroll={{ y: "calc(68vh - 4em)", x: 1300 }}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={displayData}
            loading={loading}
            scroll={{ y: "calc(68vh - 4em)", x: 1300 }}
            rowClassName={() => "gray"}
          />
        )}
      </div>
    </>
  );
};

export default TagMasterTable;
