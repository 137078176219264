export const REQUEST_MASTER_MOBILE_COUPON = "REQUEST_MASTER_MOBILE_COUPON";
export const SET_MASTER_MOBILE_COUPON = "SET_MASTER_MOBILE_COUPON";
export const ERROR_GET_MASTER_MOBILE_COUPON = "ERROR_GET_MASTER_MOBILE_COUPON";

export const REQUEST_CREATE_MASTER_MOBILE_COUPON = "REQUEST_CREATE_MASTER_MOBILE_COUPON";
export const SUCCESS_CREATE_MASTER_MOBILE_COUPON = "SUCCESS_CREATE_MASTER_MOBILE_COUPON";
export const ERROR_CREATE_MASTER_MOBILE_COUPON = "ERROR_CREATE_MASTER_MOBILE_COUPON";

export const REQUEST_UPDATE_MASTER_MOBILE_COUPON = "REQUEST_UPDATE_MASTER_MOBILE_COUPON";
export const SUCCESS_UPDATE_MASTER_MOBILE_COUPON = "SUCCESS_UPDATE_MASTER_MOBILE_COUPON";
export const ERROR_UPDATE_MASTER_MOBILE_COUPON = "ERROR_UPDATE_MASTER_MOBILE_COUPON";

export const REQUEST_DELETE_MASTER_MOBILE_COUPON = "REQUEST_DELETE_MASTER_MOBILE_COUPON";
export const SUCCESS_DELETE_MASTER_MOBILE_COUPON = "SUCCESS_DELETE_MASTER_MOBILE_COUPON";
export const ERROR_DELETE_MASTER_MOBILE_COUPON = "ERROR_DELETE_MASTER_MOBILE_COUPON";


export const SET_MESSAGE_MASTER_MOBILE_COUPON = "SET_MESSAGE_MASTER_MOBILE_COUPON";