import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUsers } from "../../store/user/reducer";
import { REQUEST_ASSIGN_ROLE_USER } from "../../store/user/userActionType";
import { IUser } from "../../types/user";
interface Props {
  user: IUser;
  isOpen: any;
  onClose: any;
}
const UserAssignRoleConfirmation: React.FC<Props> = ({
  isOpen,
  onClose,
  user,
}) => {
  const dispatch = useDispatch();
  const { users, busy, message } = useUsers();

  const toast = useToast();

  useEffect(() => {
    if (
      !busy &&
      !message &&
      !(users || []).find((acc: Record<string, any>) => acc._id === user._id)
    ) {
      onClose();
    }
  }, [user._id, users, busy, message, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User Pro</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Text color="red">
            {get(user, "userType") === "ADMIN"
              ? "Are sure to remove ADMIN User ? "
              : "Are sure to make ADMIN User ? "}
          </Text>
          <Text> {get(user, "email")}</Text>
          {message && (
            <Alert status="error" zIndex={100}>
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            onClick={() => {
              if (get(user, "_id")) {
                dispatch({
                  type: REQUEST_ASSIGN_ROLE_USER,
                  payload: {
                    userId: get(user, "_id"),
                    userType:
                      get(user, "userType") === "ADMIN" ? "USER" : "ADMIN",
                  },
                  onSuccess: () => {
                    toast({
                      title:
                        get(user, "userType") === "ADMIN"
                          ? `From now ${get(user, "email")} is USER`
                          : `From now ${get(user, "email")} is ADMIN`,
                      status: "success",
                      duration: 3000,
                      position: "top",
                      isClosable: true,
                    });
                    onClose();
                  },
                });
              }
            }}
            disabled={busy}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default UserAssignRoleConfirmation;
