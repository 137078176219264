import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  AlertTitle,
  Button,
  Drawer,
  DrawerBody,
  IconButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { get } from "lodash";
import { FormField } from "../form/formField/FormField";
import {
  REQUEST_CREATE_MASTER_SUSPEND,
  REQUEST_UPDATE_MASTER_SUSPEND,
  SET_MESSAGE_MASTER_SUSPEND,
} from "../../store/suspendType/suspendActionTypes";
import { useSuspendsMaster } from "../../store/suspendType/reducer";

interface Props {
  suspend?: any;
  isOpen: any;
  onClose: any;
}

const addSchema = Yup.object({
  reason: Yup.string().required("Required"),
});

const SuspendTypeEditDrawer: React.FC<Props> = ({
  suspend,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const { masterSuspends, busy, message } = useSuspendsMaster();
  const toast = useToast();
  const [isDisabled, setDisabled] = useState(!!(suspend && suspend._id));

  const initialValue = useMemo(() => {
    if (suspend) {
      return {
        reason: suspend.reason,
      };
    }
    return {
      reason: "",
    };
  }, [suspend]);

  const onSubmit = (values: Record<string, any>) => {
    if (suspend) {
      let payload: Record<string, any> = {
        reason: values.reason,
      };
      const data = { payload, _id: suspend._id };
      dispatch({ type: REQUEST_UPDATE_MASTER_SUSPEND, data });
    } else {
      let payload: Record<string, any> = {
        reason: values.reason,
      };
      dispatch({ type: REQUEST_CREATE_MASTER_SUSPEND, payload });
    }
  };

  const coupons = useMemo(() => {
    return masterSuspends;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (suspend) {
      const couponFromStore = masterSuspends.find(
        (ml: any) => ml._id === suspend._id
      );
      if (couponFromStore !== suspend) {
        toast({
          title: "Success.",
          description: "Suspend is updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        onClose();
      }
    } else if (coupons.length !== masterSuspends.length) {
      toast({
        title: "Success.",
        description: "Suspend is created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [coupons, masterSuspends, suspend, onClose, toast]);

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, errors, touched, submitForm }) => (
        <Form>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size={"md"}
            onClose={() => {
              onClose();
              dispatch({ type: SET_MESSAGE_MASTER_SUSPEND });
            }}
            closeOnOverlayClick={busy}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                <Flex>
                  {get(suspend, "reason") || "Add Suspend"}
                  <Spacer></Spacer>
                </Flex>

                {message && (
                  <Alert status="error">
                    <AlertTitle>{message}</AlertTitle>
                  </Alert>
                )}
              </DrawerHeader>
              <DrawerBody>
                <FormField
                  required={true}
                  //   disabled={isDisabled}
                  name="Suspend Reason : "
                  fieldName="reason"
                  type="text"
                  error={touched.reason ? (errors.reason as string) : undefined}
                />
              </DrawerBody>
              <DrawerFooter backgroundColor={"gray.100"}>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    onClose();
                    dispatch({ type: SET_MESSAGE_MASTER_SUSPEND });
                  }}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={submitForm}
                  //   disabled={busy || isDisabled}
                >
                  {suspend && suspend._id ? "Update" : "Save"}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};

export default SuspendTypeEditDrawer;
