import { useEffect, useMemo, useState } from "react";

import {
    Alert,
    AlertTitle,
    Button,
    Drawer,
    DrawerBody,
    IconButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Spacer,
    useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { get } from "lodash";
import Select from "react-select";
import { MdModeEditOutline } from "react-icons/md";
import { FormField } from "../form/formField/FormField";
import { ICoupons } from "../../types/coupon";
import { useCouponsMaster } from "../../store/coupon/reducer";
import { REQUEST_CREATE_MASTER_COUPON, REQUEST_UPDATE_MASTER_COUPON, SET_MESSAGE_MASTER_COUPON } from "../../store/coupon/couponActionType";
import { FormFieldCalender } from "../form/formField/FormFieldCalender";
import moment from "moment";

interface Props {
    coupon?: ICoupons;
    isOpen: any;
    onClose: any;
}

const addSchema = Yup.object({
    couponId: Yup.string().required("Required"),
    couponName: Yup.string().required("Required"),
    discountDuration: Yup.object().required("Required"),
    discountDurationInMonth: Yup.number().optional().nullable(true)
        // checking self-equality works for NaN, transforming it to null
        .transform((_, val) => val === Number(val) ? val : null),
    discountType: Yup.object().required("Required"),
    discountOff: Yup.number().required("Required"),
    maxRedemptions: Yup.number().optional().nullable(true)
        // checking self-equality works for NaN, transforming it to null
        .transform((_, val) => val === Number(val) ? val : null),
    redemptionsTill: Yup.number().optional().nullable(true)
        // checking self-equality works for NaN, transforming it to null
        .transform((_, val) => val === Number(val) ? val : null),

});


const CouponEditDrawer: React.FC<Props> = ({ coupon, isOpen, onClose }) => {

    const { masterCoupons, busy, message } = useCouponsMaster()
    const toast = useToast();
    const [isDisabled, setDisabled] = useState(!!(coupon && coupon._id));

    const dispatch = useDispatch();


    const discountDurationOptions: any[] = useMemo(() => {
        return [
            // once , repeat 
            { value: "once", label: "once" },
            // { value: "repeating", label: "repeating" },
            { value: "forever", label: "forever" },
            // { value: "day", label: "day" },
        ];
    }, []);


    const discountTypeOptions: any[] = useMemo(() => {
        // precet , amount
        return [
            { value: "amount", label: "amount" },
            { value: "percent", label: "percent" },
        ];
    }, []);


    const initialValue = useMemo(() => {
        if (coupon) {
            return {
                couponId: coupon.couponId,
                couponName: coupon.couponName,
                discountDuration: discountDurationOptions.find((to) => to.value === coupon.discountDuration),
                discountDurationInMonth: coupon.discountDurationInMonth,
                discountType: discountTypeOptions.find((to) => to.value === coupon.discountType),
                discountOff: coupon.discountOff,
                maxRedemptions: coupon.maxRedemptions,
                redemptionsTill: coupon.redemptionsTill ? moment(coupon.redemptionsTill * 1000).format("MM/DD/YYYY") : null,
            };
        }
        return {
            couponId: "",
            couponName: "",
            discountDuration: "",
            discountDurationInMonth: null,
            discountType: "",
            discountOff: 0,
            active: true,
            maxRedemptions: null,
            redemptionsTill: null,
        };
    }, [coupon, discountDurationOptions, discountTypeOptions]);
    
    const onSubmit = (values: Record<string, any>) => {


        if (coupon) {
            let payload: Record<string, any> = {
                couponName: values.couponName,

            };
            const data = { payload, _id: coupon._id };
            dispatch({ type: REQUEST_UPDATE_MASTER_COUPON, data });
        } else {
            let payload: Record<string, any> = {
              couponId: values.couponId,
              couponName: values.couponName,
              discountDuration: values.discountDuration.value,
              discountDurationInMonth:
                values.discountDurationInMonth === ""
                  ? null
                  : values.discountDurationInMonth,
              discountType: values.discountType.value,
              discountOff: values.discountOff,
              maxRedemptions:
                values.maxRedemptions === "" ? null : values.maxRedemptions,
              redemptionsTill:
                values.redemptionsTill === ""
                  ? null
                  : values.redemptionsTill / 1000,
            };

            dispatch({ type: REQUEST_CREATE_MASTER_COUPON, payload });
        }
    };

    const products = useMemo(() => {
        return masterCoupons;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (coupon) {
            const productFromStore = masterCoupons.find((ml: any) => ml._id === coupon._id);
            if (productFromStore !== coupon) {
                toast({
                    title: "Success.",
                    description: "Product is updated.",
                    status: "success",
                    isClosable: true,
                    position: "top-right",
                });
            }
        } else if (products.length !== masterCoupons.length) {
            toast({
                title: "Success.",
                description: "Product is created.",
                status: "success",
                isClosable: true,
                position: "top-right",
            });
            onClose();
        }
    }, [products, masterCoupons, coupon, onClose, toast]);




    return (
        <Formik
            initialValues={initialValue}
            validationSchema={addSchema}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, errors, touched, submitForm }) => (
                <Form>
                    <Drawer
                        isOpen={isOpen}
                        placement="right"
                        size={"md"}
                        onClose={() => {
                            onClose()
                            dispatch({ type: SET_MESSAGE_MASTER_COUPON })
                        }}
                        closeOnOverlayClick={busy}
                    >
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                                <Flex>
                                    {get(coupon, "title") || "Add link"}
                                    <Spacer></Spacer>
                                    {coupon && coupon._id && (
                                        <IconButton
                                            aria-label="update link"
                                            key={"updateLinkButton"}
                                            alignSelf={"end"}
                                            onClick={() => {
                                                setDisabled(!isDisabled);
                                            }}
                                            icon={<MdModeEditOutline />}
                                        ></IconButton>
                                    )}
                                </Flex>

                                {message && (
                                    <Alert status="error">
                                        <AlertTitle>{message}</AlertTitle>
                                    </Alert>
                                )}
                            </DrawerHeader>
                            <DrawerBody>

                                <FormField
                                    required={true}
                                    disabled={!!(isDisabled || coupon)}
                                    name="Coupon Id : "
                                    fieldName="couponId"
                                    type="text"
                                    error={touched.couponId && errors.couponId ? (errors.couponId as string) : undefined}
                                />

                                <FormField
                                    disabled={isDisabled}
                                    required={true}
                                    name="Coupon Name : "
                                    fieldName="couponName"
                                    type="text"
                                    error={touched.couponName && errors.couponName ? (errors.couponName as string) : undefined}
                                />

                                <FormControl isRequired>
                                    <Flex width="100%">
                                        <HStack>
                                            <FormLabel>Discount Use : </FormLabel>
                                            <Spacer></Spacer>
                                            <p>{errors.discountDuration}</p>
                                        </HStack>
                                    </Flex>
                                    <Select
                                        value={values["discountDuration"]}
                                        name="discountDuration"
                                        isDisabled={!!(isDisabled || coupon)}
                                        onChange={(event) => {
                                            //@ts-ignore
                                            setFieldValue("discountDuration", event);
                                        }}
                                        options={discountDurationOptions}
                                        placeholder="Select a Type"
                                    />
                                </FormControl>
                                {values.discountDuration.value === "repeating" ?
                                    <FormField
                                        disabled={!!(isDisabled || coupon)}
                                        required={true}
                                        name="Discount Month : "
                                        fieldName="discountDurationInMonth"
                                        type="number"
                                        error={
                                            touched.discountDurationInMonth && errors.discountDurationInMonth
                                                ? (errors.discountDurationInMonth as string)
                                                : undefined
                                        }
                                    /> : null}

                                <FormControl isRequired>
                                    <Flex width="100%">
                                        <HStack>
                                            <FormLabel>Discount Type : </FormLabel>
                                            <Spacer></Spacer>
                                            <p>{errors.discountType}</p>
                                        </HStack>
                                    </Flex>
                                    <Select
                                        value={values["discountType"]}
                                        name="discountType"
                                        isDisabled={!!(isDisabled || coupon)}
                                        onChange={(event) => {
                                            //@ts-ignore
                                            setFieldValue("discountType", event);
                                        }}
                                        options={discountTypeOptions}
                                        placeholder="Select a Type"
                                    />
                                </FormControl>


                                <FormField
                                    disabled={!!(isDisabled || coupon)}
                                    required={true}
                                    name="Discount off : "
                                    fieldName="discountOff"
                                    type="number"
                                    error={
                                        touched.discountOff && errors.discountOff
                                            ? (errors.discountOff as string)
                                            : undefined
                                    }
                                />
                                <FormField
                                    disabled={!!(isDisabled || coupon)}
                                    required={false}
                                    name="Max redemptions : "
                                    fieldName="maxRedemptions"
                                    type="number"
                                    error={
                                        touched.maxRedemptions && errors.maxRedemptions
                                            ? (errors.maxRedemptions as string)
                                            : undefined
                                    }
                                />



                                {coupon && coupon._id ?

                                    <FormField
                                        disabled={!!(isDisabled || coupon)}
                                        required={false}
                                        name="Expire At : "
                                        fieldName="redemptionsTill"
                                        type="string"
                                        error={
                                            touched.redemptionsTill && errors.redemptionsTill
                                                ? (errors.redemptionsTill as string)
                                                : undefined
                                        }
                                    /> :
                                    <FormFieldCalender
                                        // value={values["redemptionsTill"]}
                                        required={true}
                                        name={"Expire at : "}
                                        fieldName={"redemptionsTill"}
                                        placeholder={"Select a Date"}
                                        error={touched.redemptionsTill ? (errors.redemptionsTill as string) : undefined}
                                    />
                                }


                            </DrawerBody>
                            <DrawerFooter backgroundColor={"gray.100"}>
                                <Button
                                    variant="outline"
                                    mr={3}
                                    onClick={() => {
                                        onClose()
                                        dispatch({ type: SET_MESSAGE_MASTER_COUPON })
                                    }}
                                    disabled={busy}
                                >
                                    Close
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    onClick={submitForm}
                                    disabled={busy || isDisabled}
                                >
                                    {coupon && coupon._id ? "Update" : "Save"}
                                </Button>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </Form>
            )}
        </Formik>
    )
}

export default CouponEditDrawer