import { Flex, FormControl, FormLabel, HStack, Spacer } from "@chakra-ui/react";
import { Field } from "formik";
import React from "react";
import styles from "./FormField.module.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-classic-plus";

export interface FieldType {
  required: boolean;
  name: string;
  fieldName: string;
  type: string;
  disabled?: boolean;
  error: string | undefined;
}

export const FormFieldEditor: React.FC<FieldType> = (props) => {
  
  return (
    <>
      <FormControl isRequired={props.required}>
        <Flex width="100%">
          <HStack>
            <FormLabel>{props.name}</FormLabel>
            <Spacer></Spacer>
            <p className={styles.error}>{props.error}</p>
          </HStack>
        </Flex>
        <Field
          name={props.fieldName}
          type={props.type}
          className={props.error ? styles.errorField : styles.field}
        >
          {/* @ts-ignore */}
          {({ field, form, meta: { touched } }) => {
            return (
              // <ReactQuill
              //   value={field.value}
              //   onChange={field.onChange(field.name)}
              //   theme="snow"
              // />
              <>
              {/* {console.log(ClassicEditor.builtinPlugins)} */}
                <CKEditor
                  disabled={props.disabled}
                  editor={ClassicEditor}
                  data={field.value}
                  config={{
                    removePlugins: ["EasyImage","ImageUpload","MediaEmbed"]
                }}
                  onChange={(event: any, editor: any) => {
                    form.setFieldValue(props.fieldName, editor.getData());
                  }}
                />
              </>
            );
          }}
        </Field>
      </FormControl>
    </>
  );
};
