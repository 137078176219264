import axios from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { REQUEST_BETA_USERS, SET_BETA_USERS } from "./betaUserActionType";

function* requestBetaUsers(action: Record<string, any>): any {
  try {
    const result: any = yield call(getBetaUsers);
    yield put({ type: SET_BETA_USERS, payload: result.data });
  } catch (err) {
    console.error(`Error fetching switched account`, action);
  }
}
export function getBetaUsers() {
  return axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/beta_user`,
    withCredentials: true,
  });
}
const betaUserSaga = function* () {
  yield all([takeLatest(REQUEST_BETA_USERS, requestBetaUsers)]);
};

export default betaUserSaga;
