import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    REQUEST_MASTER_ANALYTICS,
    SET_MASTER_ANALYTICS,
    ERROR_GET_MASTER_ANALYTICS,
} from "./analyticsActionTypes"

// function* requestMasterAnalytics(action: Record<string, any>): any {
//     try {
//         const result: any = yield call(getAnalyticsMaster);
//         yield put({ type: SET_MASTER_ANALYTICS, payload: result.data });
//     } catch (error: any) {
//         console.log(error);
//         let message =
//             "Something went wrong, please try again after some time or Refresh the Page.";
//         if (get(error, "response.status") === 500) {
//             message = "Something happened wrong try again after sometime.";
//         } else if (get(error, "response.status") === 422) {
//             message = error.response.data.message || "please provide valid contain";
//         } else if (get(error, "response.status") === 415) {
//             message = error.response.data.message;
//         }
//         yield put({ type: ERROR_GET_MASTER_ANALYTICS, payload: message });
//     }
// }

function* requestMasterAnalytics(action: Record<string, any>): any {
    // console.log(action.payload)
    let data = {
        endDate: "2022-12-31T05:53:12.084Z",
        startDate: "2022-05-30T05:53:12.084Z"
    }
    try {
        const result = yield call(getAnalyticsMaster, action.payload === undefined ? data :action.payload);
        yield put({
            type: SET_MASTER_ANALYTICS,
            payload: result.data,
        });
        // update relevant category as well
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_GET_MASTER_ANALYTICS, payload: message });
    }
}


// export function getAnalyticsMaster() {
//     return axios({
//         method: "GET",
//         url: `${process.env.REACT_APP_BASE_URL}/admin/analytics`,
//         withCredentials: true,
//     });
// }

export function getAnalyticsMaster(payload: Record<string, any>) {
    return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/admin/analytics`,
        data: payload,
        withCredentials: true,
    });
}

const analyticsMasterSaga = function* () {
    yield all([
        // takeLatest(REQUEST_MASTER_ANALYTICS, requestMasterAnalytics),
        takeLatest(REQUEST_MASTER_ANALYTICS, requestMasterAnalytics),
    ])
}

export default analyticsMasterSaga;