import { IconButton, useDisclosure } from "@chakra-ui/react";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { BiHide, BiShowAlt } from "react-icons/bi";
// import PromoCodeEditDrawer from "../drawer/PromoCodeEditDrawer";
import { IPromoCode } from "../../types/promocde";
import PromoCodeDeactiveConfirmation from "../modals/PromoCodeDeactiveConfirmation";
import { usePromoCodeMaster } from "../../store/promoCode/reducer";
import { Spin, Table } from "antd";
import { IoMdTrash } from "react-icons/io";
import PromocodeDelete from "../modals/PromocodeDelete";

const PromoCodeMasterTable = () => {
  const { masterPromoCode, busy } = usePromoCodeMaster();

  const [targetProduct, setTargetProduct] = useState<IPromoCode>();
  // const {
  //     isOpen: isDeleteOpen,
  //     onOpen: onDeleteOpen,
  //     onClose: onDeleteClose,
  // } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deletePromocode = (product: IPromoCode) => {
    setTargetProduct(product);
    onDeleteOpen();
  };
  const artchiveProduct = (product: IPromoCode) => {
    setTargetProduct(product);
    onDeativeOpen();
  };

  const {
    isOpen: isDeativeOpen,
    onOpen: onDeativeOpen,
    onClose: onDeativeClose,
  } = useDisclosure();

  // const viewProduct = (productMaster: IPromoCode) => {
  //   setTargetProduct(productMaster);
  //   onViewOpen();
  // };

  // const {
  //   isOpen: isViewOpen,
  //   onOpen: onViewOpen,
  //   onClose: onViewClose,
  // } = useDisclosure();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(
      masterPromoCode.map((value: IPromoCode, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        couponId: get(value, "couponId.couponId"),
        promotionCode: value.promotionCode,
        value: value,
      }))
    );
    setLoading(false);
  }, [masterPromoCode]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
    },
    {
      title: "",
      dataIndex: "value",
      align: "center" as const,
      render: (value: any) => (
        <>
          <IconButton
            aria-label="Show"
            icon={value.active ? <BiShowAlt /> : <BiHide />}
            onClick={() => {
              artchiveProduct(value);
            }}
          ></IconButton>
        </>
      ),
    },
    {
      title: "COUPON CODE",
      dataIndex: "couponId",
    },
    {
      title: "PROMOCODE CODE",
      dataIndex: "promotionCode",
    },
    {
      title: "ACTION",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <IconButton
            aria-label="delete link"
            key={"deleteLinkButton"}
            marginLeft={2}
            onClick={() => {
              deletePromocode(value);
            }}
            icon={<IoMdTrash />}
          ></IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "92vw",
          maxWidth: "92vw",
          height: "90vh",
          top: "10vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && data.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 4em)", x: "auto" }}
            rowClassName={() => "gray"}
          />
        )}
      </div>

      {isDeativeOpen && targetProduct && (
        <PromoCodeDeactiveConfirmation
          promocode={targetProduct}
          isOpen={isDeativeOpen}
          onClose={onDeativeClose}
        />
      )}

      {/* {isViewOpen && (
        <PromoCodeEditDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          promocode={targetProduct}
        />
      )} */}
      {isDeleteOpen && targetProduct && (
        <PromocodeDelete
          promocode={targetProduct}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
    </>
  );
};

export default PromoCodeMasterTable;
