import {
  Button,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import { Spin, Table } from "antd";
import axios from "axios";

import moment from "moment";
import { useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { useCouponsMaster } from "../../store/coupon/reducer";
import { ICoupons } from "../../types/coupon";
import CouponEditDrawer from "../drawer/CouponEditDrawer";
import CouponDelete from "../modals/CouponDelete";

const CouponMasterTable = () => {
  const { masterCoupons, busy } = useCouponsMaster();
  const [data1, setData1] = useState([]);
  const [loader, setLoader] = useState(false);

  const [targetCoupon, setTargetCoupon] = useState<ICoupons>();

  useEffect(() => {
    setLoader(true);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/stripe/coupons/stripeCoupon`,
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Credentials": "true",
      },
    })
      .then((res) => {
        // console.log(res.data);
        setLoader(false);
        setData1(
          res.data.map((value: any, index: number) => ({
            _id: value._id,
            key: index + 1,
            Index: index + 1,
            couponId: value.couponId,
            couponName: value.couponName,
            discountDuration: value.discountDuration,
            discountType: value.discountType,
            discountOff: value.discountOff,
            redemptionsTill: value.redemptionsTill
              ? moment(value.redemptionsTill * 1000).format("MM/DD/YYYY")
              : "Nan",
          }))
        );
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  }, []);

  // const {
  //     isOpen: isDeleteOpen,
  //     onOpen: onDeleteOpen,
  //     onClose: onDeleteClose,
  // } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deleteCoupon = (couponmaster: ICoupons) => {
    setTargetCoupon(couponmaster);
    onDeleteOpen();
  };
  const viewProduct = (linkMaster: ICoupons) => {
    setTargetCoupon(linkMaster);
    onViewOpen();
  };

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(
      masterCoupons.map((value: ICoupons, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        couponId: value.couponId,
        couponName: value.couponName,
        discountDuration: value.discountDuration,
        discountType: value.discountType,
        discountOff: value.discountOff,
        redemptionsTill: value.redemptionsTill
          ? moment(value.redemptionsTill * 1000).format("MM/DD/YYYY")
          : "Nan",
        value: value,
      }))
    );
    setLoading(false);
  }, [masterCoupons]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
      maxWidth: 50,
    },
    {
      title: "COUPON ID",
      dataIndex: "couponId",
    },
    {
      title: "NAME",
      dataIndex: "couponName",
    },
    {
      title: "DURATION",
      dataIndex: "discountDuration",
    },
    {
      title: "TYPE",
      dataIndex: "discountType",
    },
    {
      title: "DISCOUNT",
      dataIndex: "discountOff",
    },
    {
      title: "EXP. DATE",
      dataIndex: "redemptionsTill",
    },
    {
      title: "ACTION",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Button
            onClick={() => {
              viewProduct(value);
            }}
          >
            View
          </Button>
          <IconButton
            aria-label="delete link"
            key={"deleteLinkButton"}
            marginLeft={2}
            onClick={() => {
              deleteCoupon(value);
            }}
            icon={<IoMdTrash />}
          ></IconButton>
        </>
      ),
    },
  ];
  const columns1 = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
      maxWidth: 50,
    },
    {
      title: "COUPON ID",
      dataIndex: "couponId",
    },
    {
      title: "NAME",
      dataIndex: "couponName",
    },
    {
      title: "DURATION",
      dataIndex: "discountDuration",
    },
    {
      title: "TYPE",
      dataIndex: "discountType",
    },
    {
      title: "DISCOUNT",
      dataIndex: "discountOff",
    },
    {
      title: "EXP. DATE",
      dataIndex: "redemptionsTill",
    },
  ];
  return (
    <>
      <div
        style={{
          width: "94vw",
          maxWidth: "94%",
          height: "90vh",
          top: "7vh ",
          left: "2vw",
          position: "relative",
        }}
      >
        <Tabs variant="enclosed">
          <TabList>
            <Tab>Coupon</Tab>
            <Tab>Stripe Coupon</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Table
                  columns={columns}
                  dataSource={data}
                  loading={busy && data.length === 0 ? true : false}
                  scroll={{ y: "calc(68vh - 4em)", x: 1400 }}
                  rowClassName={() => "gray"}
                />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Table
                  columns={columns1}
                  dataSource={data1}
                  loading={loader ? true : false}
                  pagination={{ showSizeChanger: true }}
                  scroll={{ y: "calc(68vh - 4em)", x: true }}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>

      {isViewOpen && (
        <CouponEditDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          coupon={targetCoupon}
        />
      )}
      {isDeleteOpen && targetCoupon && (
        <CouponDelete
          coupon={targetCoupon}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
    </>
  );
};

export default CouponMasterTable;
