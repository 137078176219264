export const REQUEST_MASTER_INSTRUCTION = "REQUEST_MASTER_INSTRUCTION";
export const SET_MASTER_INSTRUCTION = "SET_MASTER_INSTRUCTION";
export const ERROR_GET_MASTER_INSTRUCTION = "ERROR_GET_MASTER_INSTRUCTION";

export const REQUEST_CREATE_MASTER_INSTRUCTION =
  "REQUEST_CREATE_MASTER_INSTRUCTION";
export const SUCCESS_CREATE_MASTER_INSTRUCTION =
  "SUCCESS_CREATE_MASTER_INSTRUCTION";
export const ERROR_CREATE_MASTER_INSTRUCTION =
  "ERROR_CREATE_MASTER_INSTRUCTION";

export const REQUEST_UPDATE_MASTER_INSTRUCTION =
  "REQUEST_UPDATE_MASTER_INSTRUCTION";
export const SUCCESS_UPDATE_MASTER_INSTRUCTION =
  "SUCCESS_UPDATE_MASTER_INSTRUCTION";
export const ERROR_UPDATE_MASTER_INSTRUCTION =
  "ERROR_UPDATE_MASTER_INSTRUCTION";

export const REQUEST_DELETE_MASTER_INSTRUCTION =
  "REQUEST_DELETE_MASTER_INSTRUCTION";
export const SUCCESS_DELETE_MASTER_INSTRUCTION =
  "SUCCESS_DELETE_MASTER_INSTRUCTION";
export const ERROR_DELETE_MASTER_INSTRUCTION =
  "ERROR_DELETE_MASTER_INSTRUCTION";

export const SET_MESSAGE_MASTER_INSTRUCTION = "SET_MESSAGE_MASTER_INSTRUCTION";
