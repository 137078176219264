import {
  Alert,
  AlertIcon,
  AlertTitle,
  Center,
  CloseButton,
  Flex,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAnalyticsMaster } from "../../store/analytics/reducer";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  // scaleBeginAtZero : true,
};

// const labels = [
//   "Node",
//   "React",
//   "Flutter",
//   "WordPress",
//   "PHP",
//   "Java Script",
//   "Android",
// ];

interface AnalyticsTableProps {
  query: string;
  confirmDate: any;
}
const AnalyticsMasterTable: React.FC<AnalyticsTableProps> = ({
  query,
  confirmDate,
}) => {
  const { masterAnalytics } = useAnalyticsMaster();
  const [errorMsg, setErrorMsg] = useState("");
  const [profileVisitCount, setProfileVisitCount] = useState<any>();
  const [contactCardCount, setContactCardCount] = useState<any>();
  const [linkVisitCount, setLinkVisitCount] = useState<any>();

  useEffect(() => {
    setProfileVisitCount({
      labels: masterAnalytics.profileVisitCount?.map((val: any[]) => {
        //@ts-ignore
        return val._id;
      }),
      datasets: [
        {
          data: masterAnalytics.profileVisitCount?.map((val: any[]) => {
            //@ts-ignore
            return val.count;
          }),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          tension: 0.1,
        },
      ],
    });
    setContactCardCount({
      labels: masterAnalytics.contactCardCount?.map((val: any[]) => {
        //@ts-ignore
        return val._id;
      }),
      datasets: [
        {
          data: masterAnalytics.contactCardCount?.map((val: any[]) => {
            //@ts-ignore
            return val.count;
          }),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          tension: 0.1,
        },
      ],
    });
    setLinkVisitCount({
      labels: masterAnalytics.linkVisitCount?.map((val: any[]) => {
        //@ts-ignore
        return val._id;
      }),
      datasets: [
        {
          data: masterAnalytics.linkVisitCount?.map((val: any[]) => {
            //@ts-ignore
            return val.count;
          }),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          tension: 0.1,
        },
      ],
    });
  }, [masterAnalytics]);

  //return sum of all values in array
  const sum = (arr: any[]) => {
    return arr?.reduce((a, b) => a + b, 0);
  };

  return (
    <>
      {errorMsg ? (
        <Alert status="error" zIndex={100}>
          <AlertIcon />
          <AlertTitle mr={2}>{errorMsg}</AlertTitle>

          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setErrorMsg("")}
          />
        </Alert>
      ) : null}

      <Flex m={20} width={"90%"}>
        {/* <Center> */}
        <div style={{ width: "30%" }} className="chart">
          {profileVisitCount ? (
            <Line options={options} data={profileVisitCount} />
          ) : null}
          <Center>
            <Text fontWeight={800} mt={10}>
              Profile Visit Count (
              {sum(
                masterAnalytics.profileVisitCount?.map((val: any[]) => {
                  //@ts-ignore
                  return val.count;
                })
              )}
              )
            </Text>
          </Center>
        </div>
        <div style={{ width: "30%", margin: "0 30px" }}>
          {contactCardCount ? (
            <Line options={options} data={contactCardCount} />
          ) : null}
          <Center>
            <Text fontWeight={800} mt={10}>
              Contact Card Count (
              {sum(
                masterAnalytics.contactCardCount?.map((val: any[]) => {
                  //@ts-ignore
                  return val.count;
                })
              )}
              )
            </Text>
          </Center>
        </div>
        {/* </Center> */}
      </Flex>

      <Flex m={20} width={"100%"}>
        {/* <Center> */}
        <div style={{ width: "30%" }}>
          {linkVisitCount ? (
            <Line options={options} data={linkVisitCount} />
          ) : null}
          <Center>
            <Text fontWeight={800} mt={10}>
              Link Visit Count (
              {sum(
                masterAnalytics.linkVisitCount?.map((val: any[]) => {
                  //@ts-ignore
                  return val.count;
                })
              )}
              )
            </Text>
          </Center>
        </div>
        {/* </Center> */}
      </Flex>
      {/* </div> */}
    </>
  );
};

export default AnalyticsMasterTable;
