import {
  Center,
  Flex,
  Heading,
  HStack,
  Input,
  Spacer,
  // useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReportMasterTable from "../../componenets/ReportMasterTable/ReportMasterTable";
import { useReportMaster } from "../../store/report/reducer";
import { REQUEST_MASTER_REPORT } from "../../store/report/reportActionTypes";
import { BrowserView, MobileView } from "react-device-detect";

const Report = () => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { masterReport, busy } = useReportMaster();

  useEffect(() => {
    if (!busy && !(masterReport || []).length) {
      dispatch({ type: REQUEST_MASTER_REPORT });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [searchQuery, setSearch] = useState("");

  return (
    <>
    <MobileView>
    <VStack top={5} position="relative">
          <Heading  size="lg" fontWeight="semibold" color="black.400">
            Report
          </Heading>

          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <HStack>
              <Center>
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </Center>
            </HStack>
          </Heading>
      </VStack>
    </MobileView>
    <BrowserView>
      <VStack top={5} position="relative">
        <Flex w="92%">
          <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
            Report
          </Heading>

          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <HStack>
              {/* <Center
                  bg="tomato"
                  w="255px"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Product
                </Center> */}
              <Center>
                <Input
                  id="search"
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></Input>
              </Center>
            </HStack>
          </Heading>
        </Flex>
      </VStack>
      </BrowserView>
      <ReportMasterTable query={searchQuery} />
    </>
  );
};

export default Report;
