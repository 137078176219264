import { Button, IconButton, useDisclosure } from "@chakra-ui/react";
import { Spin, Table } from "antd";
import { get } from "lodash";

import { useEffect, useState, useMemo } from "react";
import { BiHide, BiShowAlt } from "react-icons/bi";
import { IoMdTrash } from "react-icons/io";
import { useDispatch } from "react-redux";
import { REQUEST_LINK_CATEGORY } from "../../store/linkCategory/linkCategoryActionType";
import { useLinkCategories } from "../../store/linkCategory/reducer";
import { REQUEST_MASTER_LINKS } from "../../store/linkMaster/linkActionTypes";
import { useLinksMaster } from "../../store/linkMaster/reducer";
import { ILinks } from "../../types/links";
import LinkMasterEditDrawer from "../drawer/LinkMasterEditDrawer";
import LinkDeactiveConfirmation from "../modals/linkDeactiveConfirmation";
import LinkDeleteConfirmation from "../modals/LinkDeleteConfirmation";

export interface Props {
  searchQuery: string;
}

const LinksMasterTable: React.FC<Props> = ({ searchQuery: query }) => {
  const dispatch = useDispatch();
  const { masterLinks: links, busy } = useLinksMaster();
  const { linkCategory, busy: linkCategoryBusy } = useLinkCategories();

  useEffect(() => {
    if (!linkCategoryBusy && !(linkCategory || []).length) {
      dispatch({ type: REQUEST_LINK_CATEGORY });
    }
    if (!busy && !(links || []).length) {
      dispatch({ type: REQUEST_MASTER_LINKS });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [targetLink, setTargetLink] = useState<ILinks>();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const deleteLink = (linkMaster: ILinks) => {
    setTargetLink(linkMaster);
    onDeleteOpen();
  };

  const {
    isOpen: isDeativeOpen,
    onOpen: onDeativeOpen,
    onClose: onDeativeClose,
  } = useDisclosure();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const viewLink = (linkMaster: ILinks) => {
    setTargetLink(linkMaster);
    onViewOpen();
  };

  const hideLink = (linkMaster: ILinks) => {
    setTargetLink(linkMaster);
    onDeativeOpen();
  };

  const getCategoryName = (categoryId: any) => {
    let titles: string[] = [];
    (linkCategory || []).forEach((category: any) => {
      if (categoryId.includes(category._id)) {
        titles.push(category.title);
      }
    });
    return titles && titles.length ? titles.join(", ") : categoryId;
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const searchColumns = useMemo(() => ["title"], []);

  const search = (links: ILinks) => {
    return searchColumns.some((column) => {
      return (
        get(links, column, "")
          .toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) > -1
      );
    });
  };
  useEffect(() => {
    setData(
      links.filter(search).map((value: ILinks, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        title: value.title,
        isPro: value.isPro ? "TRUE" : "FALSE",
        type: value.type,
        category: getCategoryName(value.category),
        value: value,
      }))
    );
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkCategory, links, query]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
    },
    {
      title: "",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <IconButton
            aria-label="Show"
            icon={value.isDeactive ? <BiHide /> : <BiShowAlt />}
            onClick={() => {
              hideLink(value);
            }}
          />
        </>
      ),
    },
    {
      title: "TITLE",
      dataIndex: "title",
    },
    {
      title: "LOGO",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <img src={value.logo} alt="" width={50}></img>
        </>
      ),
    },
    {
      title: "PRO",
      dataIndex: "isPro",
    },
    {
      title: "TYPE",
      dataIndex: "type",
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
    },
    {
      title: "ACTION",
      dataIndex: "value",
      width: 170,
      render: (value: any) => (
        <>
          <Button
            onClick={() => {
              viewLink(value);
            }}
          >
            View
          </Button>
          <IconButton
            aria-label="delete link"
            key={"deleteLinkButton"}
            marginLeft={2}
            onClick={() => {
              deleteLink(value);
            }}
            icon={<IoMdTrash />}
          ></IconButton>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "96vw",
          maxWidth: "96vw",
          height: "93vh",
          top: "6vh",
          left: "2vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && data.length === 0 ? true : false}
            scroll={{ y: "calc(74vh - 4em)", x: 1400 }}
            rowClassName={() => "gray"}
          />
        )}
      </div>
      {isDeleteOpen && targetLink && (
        <LinkDeleteConfirmation
          link={targetLink}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
      {isDeativeOpen && targetLink && (
        <LinkDeactiveConfirmation
          link={targetLink}
          isOpen={isDeativeOpen}
          onClose={onDeativeClose}
        />
      )}

      {isViewOpen && (
        <LinkMasterEditDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          link={targetLink}
        />
      )}
    </>
  );
};

export default LinksMasterTable;
