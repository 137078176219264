import { Button, IconButton, useDisclosure } from "@chakra-ui/react";
import { Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useProductsMaster } from "../../store/product/reducer";
import { IProducts } from "../../types/product";
import ProductDeactiveConfirmation from "../modals/ProductDeactiveConfirmation";
import ProductEditDrawer from "../drawer/ProductEditDrawer";
import { BiHide, BiShowAlt } from "react-icons/bi";

interface Props {
  liveProduct: boolean;
}

const ProductsMasterTable: React.FC<Props> = ({ liveProduct }) => {
  const { masterProducts, busy } = useProductsMaster();

  const [targetProduct, setTargetProduct] = useState<IProducts>();
  // const {
  //     isOpen: isDeleteOpen,
  //     onOpen: onDeleteOpen,
  //     onClose: onDeleteClose,
  // } = useDisclosure();

  const archiveProduct = (product: IProducts) => {
    setTargetProduct(product);
    onDeativeOpen();
  };

  const {
    isOpen: isDeativeOpen,
    onOpen: onDeativeOpen,
    onClose: onDeativeClose,
  } = useDisclosure();

  const viewProduct = (productMaster: IProducts) => {
    setTargetProduct(productMaster);
    onViewOpen();
  };

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(
      masterProducts
        .filter((item: IProducts) => item.active === liveProduct)
        .map((value: IProducts, index: number) => ({
          _id: value._id,
          key: index + 1,
          Index: index + 1,
          productName: value.productName,
          productDesc: value.productDesc,
          currentPrice: value.currentPrice,
          discountPrice: value.discountPrice / 100,
          value: value,
        }))
    );
    setLoading(false);
  }, [masterProducts, liveProduct]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
    },
    {
      title: "",
      align: "center" as const,
      width: 90,
      dataIndex: "value",
      render: (value: any) => (
        <>
          <IconButton
            aria-label="Show"
            icon={value.active ? <BiShowAlt /> : <BiHide />}
            onClick={() => {
              archiveProduct(value);
            }}
          ></IconButton>
        </>
      ),
    },
    {
      title: "NAME",
      dataIndex: "productName",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "productDesc",
    },
    {
      title: "ORIGINAL PRICE",
      dataIndex: "currentPrice",
    },
    {
      title: "DISCOUNT PRICE",
      dataIndex: "discountPrice",
    },
    {
      title: "ACTION",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Button
            onClick={() => {
              viewProduct(value);
            }}
          >
            View
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "92vw",
          maxWidth: "92vw",
          height: "90vh",
          top: "8vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && data.length === 0 ? true : false}
            scroll={{ y: "calc(68vh - 4em)", x: 1400 }}
            rowClassName={() => "gray"}
          />
        )}
      </div>
      {/* {isDeleteOpen && targetProduct && (
                <LinkDeleteConfirmation
                    link={targetLink}
                    isOpen={isDeleteOpen}
                    onClose={onDeleteClose}
                />
            )} */}
      {isDeativeOpen && targetProduct && (
        <ProductDeactiveConfirmation
          product={targetProduct}
          isOpen={isDeativeOpen}
          onClose={onDeativeClose}
        />
      )}

      {isViewOpen && (
        <ProductEditDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          product={targetProduct}
        />
      )}
    </>
  );
};

export default ProductsMasterTable;
