import {
  Alert,
  AlertTitle,
  Button,
  Drawer,
  DrawerBody,
  IconButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { get } from "lodash";
import * as Yup from "yup";
import { FormField } from "../form/formField/FormField";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { MdModeEditOutline } from "react-icons/md";
import {
  REQUEST_CREATE_MASTER_INSTRUCTION,
  REQUEST_UPDATE_MASTER_INSTRUCTION,
} from "../../store/Instruction/instructionActionTypes";
import { useInstructionMaster } from "../../store/Instruction/reducer";
import { FormFieldTextArea } from "../form/formField/FormFieldTextArea";
interface Props {
  instruction?: any;
  isOpen: any;
  onClose: any;
}

// const FILE_SIZE = 3 * 1024 * 1024;

// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const addSchema = Yup.object({
  message: Yup.string().required("Required"),
});

const InstructionDrawer: React.FC<Props> = ({
  instruction,
  isOpen,
  onClose,
}) => {
  const { masterInstruction, busy, message } = useInstructionMaster();

  const dispatch = useDispatch();

  const initialValue = useMemo(() => {
    if (instruction) {
      return {
        message: instruction.message,
      };
    }
    return {
      message: "",
    };
  }, [instruction]);

  const onSubmit = (values: Record<string, any>) => {
    let payload: Record<string, any> = {
      message: values.message,
    };

    if (instruction) {
      const data = { payload, _id: instruction._id };
      dispatch({ type: REQUEST_UPDATE_MASTER_INSTRUCTION, data });
    } else {
      dispatch({ type: REQUEST_CREATE_MASTER_INSTRUCTION, payload });
    }
  };

  const toast = useToast();
  const faqs = useMemo(() => {
    return masterInstruction;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (instruction) {
      const linkFromStore = masterInstruction.find(
        (ml: any) => ml._id === instruction._id
      );
      if (linkFromStore !== instruction) {
        toast({
          title: "Success.",
          description: "Instruction is updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        onClose();
      }
    } else if (faqs.length !== masterInstruction.length) {
      toast({
        title: "Success.",
        description: "Instruction is created.",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [masterInstruction, faqs, onClose, instruction, toast]);

  const [isDisabled, setDisabled] = useState(
    !!(instruction && instruction._id)
  );

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, submitForm, setFieldValue }) => (
        <Form>
          <Drawer
            isOpen={isOpen}
            placement="right"
            size={"md"}
            onClose={onClose}
            closeOnOverlayClick={busy}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader backgroundColor={"gray.100"} alignItems={"stretch"}>
                <Flex>
                  {get(instruction, "message") || "Add instruction"}
                  <Spacer></Spacer>
                  {instruction && instruction._id && (
                    <IconButton
                      aria-label="update link"
                      key={"updateLinkButton"}
                      alignSelf={"end"}
                      onClick={() => {
                        setDisabled(!isDisabled);
                      }}
                      icon={<MdModeEditOutline />}
                    ></IconButton>
                  )}
                </Flex>

                {message && (
                  <Alert status="error">
                    <AlertTitle>{message}</AlertTitle>
                  </Alert>
                )}
              </DrawerHeader>
              <DrawerBody>
                <FormFieldTextArea
                  required={true}
                  disabled={isDisabled}
                  name="Instruction Message : "
                  fieldName="message"
                  type="text"
                  error={
                    touched.message ? (errors.message as string) : undefined
                  }
                />
              </DrawerBody>
              <DrawerFooter backgroundColor={"gray.100"}>
                <Button
                  variant="outline"
                  mr={3}
                  onClick={onClose}
                  disabled={busy}
                >
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={submitForm}
                  disabled={busy || isDisabled}
                >
                  {instruction && instruction._id ? "Update" : "Save"}
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Form>
      )}
    </Formik>
  );
};
export default InstructionDrawer;
