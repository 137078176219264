import {
  Center,
  Flex,
  Heading,
  HStack,
  Spacer,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import { REQUEST_MASTER_SUSPEND } from "../../store/suspendType/suspendActionTypes";
import { useSuspendsMaster } from "../../store/suspendType/reducer";
import SuspendTypeTable from "../../componenets/SuspendTypeTable/SuspendTypeTable";
import SuspendTypeEditDrawer from "../../componenets/drawer/SuspendTypeEditDrawer";

const SuspendType = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { masterSuspends, busy } = useSuspendsMaster();

  useEffect(() => {
    if (!busy && !(masterSuspends || []).length) {
      dispatch({ type: REQUEST_MASTER_SUSPEND });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <MobileView>
        <VStack top={5} position="relative">
          <Heading size="lg" fontWeight="semibold" color="black.400">
            Suspend Type
          </Heading>

          <Spacer></Spacer>
          <Heading size="md" fontWeight="semibold">
            <HStack>
              <Center
                bg="tomato"
                h="100%"
                color="white"
                p={3}
                onClick={() => {
                  onOpen();
                  //   setaddErrorMsg("");
                }}
                style={{ cursor: "pointer" }}
              >
                + Add Suspend Type
              </Center>
            </HStack>
          </Heading>
        </VStack>
      </MobileView>
      <BrowserView>
        <VStack top={5} position="relative">
          <Flex w="92%">
            <Heading ml="8" size="lg" fontWeight="semibold" color="black.400">
              Suspend Type
            </Heading>

            <Spacer></Spacer>
            <Heading size="md" fontWeight="semibold">
              <HStack>
                <Center
                  bg="tomato"
                  h="100%"
                  color="white"
                  p={3}
                  onClick={() => {
                    onOpen();
                    //   setaddErrorMsg("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  + Add Suspend Type
                </Center>
              </HStack>
            </Heading>
          </Flex>
        </VStack>
      </BrowserView>
      <SuspendTypeTable />
      {isOpen && <SuspendTypeEditDrawer isOpen onClose={onClose} />}
    </>
  );
};

export default SuspendType;
