import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useUsers } from "../../store/user/reducer";
import { REQUEST_RESETPOPUP } from "../../store/user/userActionType";
import { IUser } from "../../types/user";

interface Props {
  user: IUser | any;
  isOpen: any;
  onClose: any;
}

const UserVisitPopUp: React.FC<Props> = ({ isOpen, onClose, user }) => {
  const { isFirstVisit, isPrivacyAccepted } = user;
  const dispatch = useDispatch();
  const { busy, message, users } = useUsers();
  const [data, setData] = useState({
    isFirstVisit: isFirstVisit.value,
    isPrivacyAccepted: isPrivacyAccepted.value,
  });
  const toast = useToast();

  useEffect(() => {
    if (
      !busy &&
      !message &&
      !(users || []).find((acc: Record<string, any>) => acc._id === user._id)
    ) {
      onClose();
    }
  }, [user._id, users, busy, message, onClose]);

  const onSubmit = () => {
    const payload = {
      _id: user._id,
      payload: data,
    };
    dispatch({
      type: REQUEST_RESETPOPUP,
      payload,
      onSuccess: () => {
        toast({
          title: "Successfull submitted",
          status: "success",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        onClose();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>User visit</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Stack spacing={[1, 5]} direction={"row"}>
            <Flex>
              <input
                type="checkbox"
                name="isFirstVisit"
                id="first"
                checked={data.isFirstVisit}
                onChange={(event) => {
                  const { name, checked } = event.target;
                  setData({
                    ...data,
                    [name]: checked,
                  });
                }}
              />
              <label htmlFor="first" style={{ marginLeft: "8px" }}>
                First Visit
              </label>
            </Flex>
            <Flex>
              <input
                type="checkbox"
                name="isPrivacyAccepted"
                id="checkbox_id"
                checked={data.isPrivacyAccepted}
                onChange={(event) => {
                  const { name, checked } = event.target;
                  setData({
                    ...data,
                    [name]: checked,
                  });
                }}
              />
              <label htmlFor="checkbox_id" style={{ marginLeft: "8px" }}>
                Privacy Accept
              </label>
            </Flex>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            onClick={onSubmit}
            disabled={busy}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default UserVisitPopUp;
