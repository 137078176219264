import axios from "axios";
import { get } from "lodash";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    REQUEST_MASTER_SUSPEND,
    REQUEST_CREATE_MASTER_SUSPEND,
    REQUEST_UPDATE_MASTER_SUSPEND,
    SET_MASTER_SUSPEND,
    SUCCESS_CREATE_MASTER_SUSPEND,
    SUCCESS_UPDATE_MASTER_SUSPEND,
    ERROR_GET_MASTER_SUSPEND,
    ERROR_CREATE_MASTER_SUSPEND,
    ERROR_UPDATE_MASTER_SUSPEND,
    REQUEST_DELETE_MASTER_SUSPEND,
    ERROR_DELETE_MASTER_SUSPEND,
    SUCCESS_DELETE_MASTER_SUSPEND,
} from "./suspendActionTypes"

function* requestMasterSuspends(action: Record<string, any>): any {
    try {
        const result: any = yield call(getSuspendsMaster);
        yield put({ type: SET_MASTER_SUSPEND, payload: result.data });
        // console.warn(result.data);
        
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_GET_MASTER_SUSPEND, payload: message });
    }
}

function* requestUpdateMasterSuspends(action: Record<string, any>): any {
    try {
        const result = yield call(
            updateMasterSuspends,
            action.data.payload,
            action.data._id
        )
        yield put({
            type: SUCCESS_UPDATE_MASTER_SUSPEND,
            payload: result.data,
        });
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_UPDATE_MASTER_SUSPEND, payload: message });
    }
}

function* requestCreateMasterSuspends(action: Record<string, any>): any {
    try {
        const result = yield call(createMasterSuspends, action.payload);
        yield put({
            type: SUCCESS_CREATE_MASTER_SUSPEND,
            payload: result.data,
        });
        // update relevant category as well
    } catch (error: any) {
        console.log(error);
        let message =
            "Something went wrong, please try again after some time or Refresh the Page.";
        if (get(error, "response.status") === 500) {
            message = "Something happened wrong try again after sometime.";
        } else if (get(error, "response.status") === 422) {
            message = error.response.data.message || "please provide valid contain";
        } else if (get(error, "response.status") === 415) {
            message = error.response.data.message;
        }
        yield put({ type: ERROR_CREATE_MASTER_SUSPEND, payload: message });
    }
}

function* requestDeleteMasterSuspends(action: Record<string, any>): any {
    try {
      yield call(deleteMasterSuspends, get(action, "payload._id", ""));
      yield put({
        type: SUCCESS_DELETE_MASTER_SUSPEND,
        payload: get(action, "payload._id", ""),
      });
    } catch (error: any) {
      let message =
        "Something went wrong, please try again after some time or Refresh the Page.";
      if (get(error, "response.status") === 500) {
        message = "Something happened wrong try again after sometime.";
      } else if (get(error, "response.status") === 422) {
        message = "please provide valid contain";
      } else if (get(error, "response.status") === 415) {
        message = error.response.data.message;
      }
      yield put({ type: ERROR_DELETE_MASTER_SUSPEND, payload: message });
    }
  }


export function getSuspendsMaster() {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/admin/suspendType`,
        withCredentials: true,
    });
}

export function updateMasterSuspends(payload: Record<string, any>, _id: string) {
    return axios({
        method: "patch",
        url: `${process.env.REACT_APP_BASE_URL}/admin/suspendType/${_id}`,
        data: payload,
        withCredentials: true,
    });
}

export function createMasterSuspends(payload: Record<string, any>) {
    return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/admin/suspendType`,
        data: payload,
        withCredentials: true,
    });
}

export function deleteMasterSuspends(_id: string) {
    return axios({
      method: "delete",
      url: `${process.env.REACT_APP_BASE_URL}/admin/suspendType/${_id}`,
      withCredentials: true,
    });
  }


const suspendsMasterSaga = function* () {
    yield all([
        takeLatest(REQUEST_MASTER_SUSPEND, requestMasterSuspends),
        takeLatest(REQUEST_DELETE_MASTER_SUSPEND, requestDeleteMasterSuspends),
        takeLatest(REQUEST_UPDATE_MASTER_SUSPEND, requestUpdateMasterSuspends),
        takeLatest(REQUEST_CREATE_MASTER_SUSPEND, requestCreateMasterSuspends),
    ])
}

export default suspendsMasterSaga;