import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  AlertTitle,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTutorialsMaster } from "../../store/tutorial/reducer";
import { REQUEST_DELETE_MASTER_TUTORIAL } from "../../store/tutorial/tutorialActionTypes";
interface Props {
  tutorial: Record<string, any>;
  isOpen: any;
  onClose: any;
}
const TutorialDeleteConfirmation: React.FC<Props> = ({
  tutorial,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { masterTutorials: tutorials, busy, message } = useTutorialsMaster();
  const { _id } = tutorial;
  const toast = useToast();

  useEffect(() => {
    const findTutorial = tutorials.find(
      (val: Record<string, any>) => val._id === _id
    );
    if (!findTutorial) {
      toast({
        title: "Success.",
        description: "Tutorial Successfully deleted..",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      onClose();
    }
  }, [_id, tutorials, onClose, toast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Tutorial</ModalHeader>
        <ModalCloseButton disabled={busy} />
        <ModalBody>
          <Text fontWeight="bold" mb="1rem">
            Are you sure to delete? {tutorial.title}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose} disabled={busy}>
            Close
          </Button>
          <Button
            colorScheme="red"
            variant={"outline"}
            mr={3}
            disabled={busy}
            onClick={() => {
              dispatch({
                type: REQUEST_DELETE_MASTER_TUTORIAL,
                payload: { _id: tutorial._id },
              });
            }}
          >
            Submit
          </Button>
        </ModalFooter>
        {message && (
          <ModalFooter>
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>{message}</AlertTitle>
              <CloseButton position="absolute" right="8px" top="8px" />
            </Alert>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default TutorialDeleteConfirmation;
