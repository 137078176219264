import React, { useEffect } from "react";
import { Button, Center, IconButton, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { IoMdTrash } from "react-icons/io";
import { Spin, Table } from "antd";
import { useInstructionMaster } from "../../store/Instruction/reducer";
import { REQUEST_MASTER_INSTRUCTION } from "../../store/Instruction/instructionActionTypes";
import InstructionDrawer from "../drawer/InstructionDrawer";
import InstructionDelete from "../modals/InstructionDelete";

const InstructionMasterTable: React.FC = () => {
  const { masterInstruction, busy } = useInstructionMaster();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!busy && !(masterInstruction || []).length) {
      dispatch({ type: REQUEST_MASTER_INSTRUCTION });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [targetItem, setTargetItem] = useState<any>();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  const viewItem = (item: any) => {
    setTargetItem(item);
    onViewOpen();
  };

  const deleteItem = (item: any) => {
    setTargetItem(item);
    onDeleteOpen();
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData(
      masterInstruction.map((value: any, index: number) => ({
        _id: value._id,
        key: index + 1,
        Index: index + 1,
        message: value.message,
        // embeddedLink: value.embeddedLink,
        // description:
        //   value.description && value.description.length > 20
        //     ? `${value.description?.slice(0, 20)}...`
        //     : value.description,
        // description: value.description
        //   ? value.description.toString().slice(0, 30)
        //   : "Nan",
        value: value,
      }))
    );
    setLoading(false);
  }, [masterInstruction]);

  const columns = [
    {
      title: "SR N0.",
      dataIndex: "Index",
      width: 80,
    },
    {
      title: "Instruction Message",
      dataIndex: "message",
    },
    {
      title: "ACTION",
      dataIndex: "value",
      render: (value: any) => (
        <>
          <Button
            onClick={() => {
              viewItem(value);
            }}
          >
            Edit
          </Button>
          {/* <IconButton
            aria-label="delete link"
            key={"deleteLinkButton"}
            marginLeft={2}
            onClick={() => {
              deleteItem(value);
            }}
            icon={<IoMdTrash />}
          ></IconButton> */}
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          width: "92vw",
          maxWidth: "92vw",
          height: "78vh",
          top: "10vh ",
          left: "4vw",
          position: "relative",
        }}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            loading={busy && data.length === 0 ? true : false}
            scroll={{ y: "calc(60vh - 4em)", x: 1300 }}
            rowClassName={() => "gray"}
          />
        )}
      </div>
      {isViewOpen && (
        <InstructionDrawer
          isOpen={isViewOpen}
          onClose={onViewClose}
          instruction={targetItem}
        />
      )}

      {isDeleteOpen && targetItem && (
        <InstructionDelete
          instruction={targetItem}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
        />
      )}
    </>
  );
};

export default InstructionMasterTable;
